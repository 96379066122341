import axios from 'axios'
import _ from 'lodash'
import ProxyUrl from '../../constants/ProxyUrls'
import moment from 'moment'

const initState = {
  events: [],
  objectViews: [],
  currentEvents: [],
}

export const state = Object.assign({}, _.cloneDeep(initState))

export const getters = {
  events: (state: any) => state.events,
  currentEvents: (state: any) => state.currentEvents,
  presentation: (state: any) => (viewId: any) => {
    const objectView = state.objectViews.find((el: any) => el.viewId === viewId)

    let presentation = ''
    if (objectView) {
      presentation = objectView.object.name
    }

    return presentation
  },
}

export const mutations = {
  createEvent(state: any, event: any) {
    event.backgroundColor = event.calendar.color
    state.events.push(event)
  },

  updateEvent(state: any, event: any) {
    const index = state.events.findIndex((e: any) => e.id === event.id)
    event.backgroundColor = event.calendar.color
    return state.events.splice(index, 1, {
      ...state.events[index],
      ...event,
    })
  },

  deleteEvent(state: any, event: any) {
    const index = state.events.findIndex((e: any) => e.id === event.id)
    return state.events.splice(index, 1)
  },

  setEvents(state: any, events: any) {
    state.events = events.map((event: any) => {
      event.backgroundColor = event.calendar.color
      return event
    })
  },

  updateCurrentEvents(state: any, currentEvents: any) {
    state.currentEvents = currentEvents
  },

  resetState(state: any) {
    Object.assign(state, _.cloneDeep(initState))
  },
}

export const actions = {
  async findAllEvents({ commit }: any, payload: any) {
    const res = await axios.get(`${ProxyUrl.getAllCalendarEvents}`, payload)
    if (Number(res.data.httpStatus) === 200) {
      if (!payload?.noCommit) {
        commit('setEvents', res.data.responseData)
      }
      return res
    } else return false
  },

  async createEvent({ commit }: any, data: any) {
    const saveData = _.cloneDeep(data)
    const resItem = await axios.post(`${ProxyUrl.createCalendarEvent}`, saveData)
    if (Number(resItem.data.httpStatus) === 200) {
      commit('createEvent', resItem.data.responseData)
      return true
    } else return false
  },

  async updateEvent({ commit }: any, data: any) {
    const saveData = _.cloneDeep(data)
    const resItem = await axios.put(`${ProxyUrl.updateCalendarEvent}`, saveData)
    if (Number(resItem.data.httpStatus) === 200) {
      commit('updateEvent', resItem.data.responseData)
      return true
    } else return false
  },

  async deleteEvent({ commit }: any, data: any) {
    try {
      const res = await axios.delete(`${ProxyUrl.deleteAllCalendarEvent}`, { data })
      if (res.status === 200) {
        commit('deleteEvent', data)
      } else return false
    } catch (error) {
      console.error(error)
      return false
    }
  },

  async updateCurrentEvents({ commit, dispatch, state }: any, payload: any) {
    const calendarId = payload?.calendarId || null
    const calendarGroup = payload?.calendarGroup || null
    const filters = payload?.filters || {}
    const events = state.events

    if (calendarId === 'all') {
      const categoryCalendarEvents = await dispatch('findCategoryCalendarEvents', filters)
      commit('updateCurrentEvents', [...events, ...categoryCalendarEvents])
      return
    }

    // private and shared calendar
    if (calendarGroup !== 'categoryCalendar' && calendarId) {
      const currentEvents = events.filter((e: any) => e.calendarId === calendarId)
      commit('updateCurrentEvents', currentEvents)
      return
    }

    // Category calendar
    if (calendarId === 'customerRequests') {
      const currentEvents = await dispatch('findCustomerRequests', filters)
      commit('updateCurrentEvents', currentEvents)
      return
    }

    if (calendarId === 'salesOrders') {
      const currentEvents = await dispatch('findSalesOrders', filters)
      commit('updateCurrentEvents', currentEvents)
      return
    }

    if (calendarId === 'reclamations') {
      const currentEvents = await dispatch('findReclamations', filters)
      commit('updateCurrentEvents', currentEvents)
      return
    }
  },

  async findCategoryCalendarEvents({ dispatch }: any, filters: any) {
    const [customerRequests, salesOrders, reclamations] = await Promise.all([
      dispatch('findCustomerRequests', filters),
      dispatch('findSalesOrders', filters),
      dispatch('findReclamations', filters),
    ])
    return [...customerRequests, ...salesOrders, ...reclamations]
  },

  async findCustomerRequests({ dispatch }: any, filters: any) {
    const filterStr = {
      noCommit: true,
      params: {
        filter: {},
      },
    }

    if (filters.constr) {
      // @ts-ignore
      filterStr.params.filter.constructorId = filters.constr.id
    }

    if (filters.executor) {
      // @ts-ignore
      filterStr.params.filter.executorId = filters.executor.id
    }

    if (filters.customer) {
      // @ts-ignore
      filterStr.params.filter.customerId = filters.customer.id
    }

    if (filters.tags) {
      // @ts-ignore
      filterStr.params.filter.tags = filters.tags
    }

    if (filters.manager) {
      // @ts-ignore
      filterStr.params.filter.managerId = filters.manager.id
    }
    return dispatch('customerRequests/findAll', filterStr, { root: true }).then((res: any) => {
      const resData = res.data || []

      return resData.map((item: any) => {
        const dt = moment(item.executionTerm)
        item.calendarId = 'customerRequests'
        item.title = item.presentation
        item.editable = false
        item.allDay = true
        item.date = dt.format('YYYY-MM-DD')
        item.start = dt.format('YYYY-MM-DD 00:00:00')
        item.end = dt.format('YYYY-MM-DD 23:59:59')
        return item
      })
    })
  },

  async findSalesOrders({ dispatch, rootState }: any, filters: any) {
    const filterStr = {
      noCommit: true,
      params: {
        filter: {},
        lang: rootState.auth.currentLanguage.code || 'pl',
      },
    }

    if (filters.customer) {
      //@ts-ignore
      filterStr.params.filter.customerId = filters.customer.id
    }

    if (filters.status) {
      //@ts-ignore
      filterStr.params.filter.statusId = filters.status
    }

    return dispatch('orders/findAll', filterStr, { root: true }).then((res: any) => {
      const resData = res.data || []

      return resData.map((item: any) => {
        const dt = moment(item.deliveryDate)
        item.calendarId = 'salesOrders'
        item.title = item.presentation
        item.editable = false
        item.allDay = true
        item.date = dt.format('YYYY-MM-DD')
        item.start = dt.format('YYYY-MM-DD 00:00:00')
        item.end = dt.format('YYYY-MM-DD 23:59:59')
        return item
      })
    })
  },

  async findReclamations({ dispatch }: any, filters: any) {
    const filterStr = {
      noCommit: true,
      params: {
        filter: {},
        statusgroupfilter: {},
      },
    }

    if (filters.period?.length === 2 && filters.period?.[0] !== null) {
      //@ts-ignore
      filterStr.params.filter.period = filters.period
    }

    if (filters.customer) {
      //@ts-ignore
      filterStr.params.filter.customerId = filters.customer.id
    }

    if (filters.status && filters.status !== 'all') {
      //@ts-ignore
      filterStr.params.statusgroupfilter.statusgroupid = filters.status
    }

    if (filters.responsible) {
      //@ts-ignore
      filterStr.params.filter.responsibleId = filters.responsible.id
    }

    if (filters.executor) {
      //@ts-ignore
      filterStr.params.filter.executorId = filters.executor.id
    }

    if (filters.manager) {
      //@ts-ignore
      filterStr.params.filter.managerId = filters.manager.id
    }

    return dispatch('reclamations/findAllReclamations', filterStr, { root: true }).then((res: any) => {
      const resData = res.data || []
      return resData.map((item: any) => {
        const dt = moment(item.executionTerm)
        item.calendarId = 'reclamations'
        item.title = item.presentation
        item.editable = false
        item.allDay = true
        item.date = dt.format('YYYY-MM-DD')
        item.start = dt.format('YYYY-MM-DD 00:00:00')
        item.end = dt.format('YYYY-MM-DD 23:59:59')
        return item
      })
    })
  },
}
