export default {
  businessProcess: {
    definitionsFormTitle: 'Edit process diagram',
    definitionsListTitle: 'Business processes',
  },
  commands: {
    accept: 'I accept',
    add: 'Add',
    addComment: 'Add comment',
    runTimeTracking: 'Run time tracking',
    completeAllConsts: 'Complete all costs',
    insertNumber: 'Insert number',
    addEvent: 'Add event',
    markAll: 'Mark all',
    activate: 'Activate',
    unmarkAll: 'Unmark all',
    enterNumber: 'Enter number',
    addRefreshResult: 'Add / Refresh result',
    addAttachment: 'Add attachment',
    editNumber: 'Edit number',
    addExtraProduct: 'Add bulk goods',
    allowManual: 'Edit',
    addFile: 'Add file',
    addFromList: 'Add from list',
    addGroup: 'Add group',
    addNew: 'Add new',
    addProduct: 'Add product',
    writeChanges: 'Write changes',
    addSetting: 'Add setting',
    addToCalendar: 'Add to calendar',
    addViewSetting: 'Add view setting',
    advancedSearch: 'Advanced Search',
    goToEditing: 'Go to editing',
    mannualEntry: 'Mannual entry',
    attachTo: 'Attach to',
    calculate: 'Recalculate',
    cancel: 'Cancel',
    cancelTaskExecution: 'Delete execution',
    change: 'Change',
    changeView: 'Change view',
    clear: 'Clear',
    close: 'Close',
    autofill: 'Autofill',
    compare: 'Compare',
    configProduct: 'Configure your product',
    confirm: 'Confirm',
    undoConfirm: 'Undo confirm',
    showRegistersRecords: 'Show registers records',
    confirmPrices: 'Confirm prices',
    confirmSelected: 'Confirm selection',
    copy: 'Copy',
    createFrom: 'Create from',
    createOrder: 'Create a contract',
    delete: 'Delete',
    deleteRestore: 'Delete / Restore',
    download: 'Download',
    edit: 'Edit',
    eventsList: 'List of events',
    execute: 'Execute',
    forward: 'Redirect',
    generate: 'Generate',
    getComputerID: 'Download Computer ID',
    getInfoEquipment: 'Download device data',
    more: 'Read more',
    moveDown: 'Fall',
    moveUp: 'Raise',
    ok: 'OK',
    open: 'Open',
    print: 'Print',
    redirect: 'Redirect',
    redirectTaskExecution: 'Execute and redirect to next step',
    refresh: 'Refresh',
    resend: 'Resend',
    resetPassword: 'Reset password',
    runAndClose: 'Run and close',
    search: 'Search',
    select: 'Choose',
    selectAll: 'Select all',
    send: 'Send',
    sendListToAuthor: 'Send the list of drivers to the author',
    sendNow: 'Send Now',
    sendResult: 'Send result',
    setDefault: 'Set default',
    setStatus: 'Set status',
    showCanceled: 'Show inactive',
    showHistory: 'Show change history',
    update: 'Refresh',
    write: 'Save',
    writeAndClose: 'Save and close',
    writeEmail: 'Email',
    changeStatus: 'Change status',
    changeDecision: 'Change decision',
    openReport: 'Open report',
    salesReport: 'Sales report',
    addCustomer: 'Add customer',
    getFileForTranslation: 'Get file for tranlsation',
    loadTranslationFromFile: 'Load translation from file',
    checkVat: 'Check VAT',
    checkVies: 'Check VIES',
    showVatInfo: 'Information VAT',
    batchesPickup: 'Pickup batches',
  },
  common: {
    searchCounterpartyAccountingGroup: 'Search counterparty accounting group',
    searchProductAccountingGroup: 'Search product accounting group',
    actions: 'Activities',
    active: 'Active',
    addinDimensions: 'Additional dimensions',
    additionalProducts: 'Additional products',
    message: 'Message',
    notSendMessage: "I don't want to get emails from 'Synergy'",
    sendMessageFromSpecific: 'I want to get messages from specific mails',
    writeComment: 'Write comment',
    technicalData: 'Technical data',
    isFirm: "I'm a firm",
    availableFields: 'Available fields',
    undefined: 'Undefined',
    firm: 'Firm',
    userState: 'Status użytkownika',
    analyzeProccess: 'Analyze proccess',
    customerFiles: 'Customer files',
    carrierData: 'Carrier data',
    rows: 'rows',
    registration: 'Registration',
    constructor: 'Constructor',
    containsWords: 'It contains the words',
    selectLanguage: 'Select language',
    decisionDate: 'Decision date',
    currency: 'Currency',
    financialPeriod: 'Financial periods',
    dispositionOfNumer: 'Disposition nr: ',
    deliveryOrderOfNumber: 'Delivery nr: ',
    issuedIn: ' from ',
    customerData: 'Customer Data',
    sales: 'Sale',
    dateAdded: 'Date added',
    numbers: 'Numbers',
    days: 'Days',
    fileDescription: 'File description',
    planedCompletitionDate: 'Planed complation date',
    deactive: 'Inactive',
    deliveryNoteData: 'Bill of lading data',
    describeProduct: 'Describe the product...',
    customerRequestNumberEdit: 'Edit customer request number',
    customerRequestNumber: 'Customer request number',
    dispositionData: 'Disposition data',
    double: 'Duble',
    emptyExecutorList: 'The list of contractors is empty....',
    emptyField: '<not filled in>.',
    emptyHistoryList: 'No records were found for changes to the objec...',
    addUpdateCostsOfComplaintOrder: 'Adding / Editing the costs of a complaint order',
    complaintVerificationProtocol: 'Complaint verification protocol',
    emptyUserList: 'The list of users is empty...',
    enterComment: 'Enter comments',
    enterName: 'Enter name',
    enterPictureName: 'Enter a picture name',
    enterQuantity: 'Enter quantity',
    enterTextHere: 'Enter text here...',
    complaintsInfo: 'Complaint details',
    erp: 'ERP',
    executionToday: 'Performance today',
    executorData: 'Data of the Task Contractor',
    executorRoleData: 'Contractor Role Data',
    externalCodes: 'External codes',
    fields: 'Fields',
    justification: 'Justification',
    files: 'Files',
    fillByTemplate: '-- Fill in from the template --.',
    filter: 'Filter',
    filters: 'Filters',
    format: 'Fromat',
    formatter: 'Formatting',
    forwarders: 'Forwarders',
    regestrationDate: 'Regestration date',
    from: 'from',
    general: 'General',
    height: 'Height',
    help: 'Help',
    history: 'History',
    hours: 'Hours',
    incorrectData: 'Incorrect data has been entered in the field!',
    items: 'Things',
    length: 'Length',
    lengthWidth: 'Length/Width',
    loading: 'Charging',
    mainData: 'Basic data',
    minutes: 'Minutes',
    modalTitle: 'Attention!',
    msg: 'Notification',
    msges: {
      selectItemFirst: 'Choose to ask for an offer first!',
    },
    navigation: 'Navigation',
    no: 'Not',
    noFieldsAvailable: 'No fields available',
    noFiltersAvailable: 'No filters available',
    noFound: '<not found>.',
    noSelectedFiles: 'No files selected',
    notEmptyField: 'This field cannot be empty',
    notSelected: 'Not selected',
    numberOfPieces: 'Number of pieces',
    orderData: 'Order data',
    partition: 'Chapter',
    perPage: 'Limit',
    picturePath: 'Path to image',
    preview: 'Preview',
    prices: 'Prices',
    processingError: 'An error occurred while performing the operation',
    productData: 'Product Data',
    products: 'Products',
    quickFilter: 'Quick filter',
    quickFilters: 'Quick filters',
    recalculation: 'Recalculation',
    register: 'Register',
    reservations: 'Reservations',
    search: 'Search...',
    searchExecution: 'Find execution',
    searchTaskExecutor: 'Find task executor',
    searchConstructor: 'Find constructor',
    searchType: 'Find type',
    searchContractor: 'Find contractor',
    searchStatus: 'Find status',
    searchRealization: 'Find realization',
    searchAgreement: 'Find agreement',
    searchSubject: 'Find subject',
    searchDecision: 'Find decision',
    searchResponsible: 'Find responsible',
    searchReason: 'Find reason',
    searchExpectation: 'Find expectation',
    searchCustomer: 'Find customer',
    searchManager: 'Find manager',
    searchExecutor: 'Find executor',
    searchItem: 'Search',
    selectDate: 'Select a date',
    selectEmployee: 'Select an employee',
    selectExecutor: 'Select a contractor',
    selectExecutorRole: 'Choose the role of the contractor',
    selectFileTitle: 'Choose a file!',
    selectForm: 'Select a document',
    selectImportance: 'Select validity',
    selectPossition: 'Select item',
    selectProductTitle: 'Choose a product!',
    selectUser: 'Select a user',
    selectMethod: 'Select method',
    selected: 'Selected',
    settings: 'Settings',
    shipData: 'Ship data',
    showMore: 'Show more',
    signatureSettings: "User's email settings",
    sortable: 'Sortable',
    styles: 'Styles',
    subsystem: 'Subsystem',
    taras: 'Tara',
    taskTemplatesData: 'Job template data',
    textPosition: 'Position in the text',
    to: 'to',
    total: 'Total',
    paid: 'Paid',
    leftToPay: 'Left to pay',
    receiveVATPerson: 'The person authorized to receive a VAT invoice',
    issueVATPerson: 'The person authorized to issue a VAT invoice',
    valueType: 'Value type',
    vehicle: 'Self-hauler/wagon',
    vendorsAndCustomers: 'Suppliers and customers',
    vendorsAndCustomersData: 'Data of suppliers and customers',
    view: 'View',
    views: 'Views',
    wastes: 'Waste',
    width: 'Width',
    yes: 'Yes',
    statisticByTraders: 'Statistics according to traders',
    myCalendar: 'My calendar',
    customerRequestsByCustomer: 'Customer requests by customer',
    customerRequestsStatistics: 'Customer requests statistics',
    customerRequestsValue: 'Value of customer requests',
    currentWeek: 'Current Week',
    previousWeek: 'Previous Week',
    weekNumber: 'Week number',
    enterWeekNumber: 'Enter week number',
    actual: 'Actual',
    projection: 'Projection',
    customerQuantity: 'Current number of customers',
    interactionQuantity: 'Current amount of interaction',
    customerRequestsQuantity: 'Number of customer requests',
    customerRequestsValueActual: 'Current value of customer requests',
    sinceLastMonth: 'Since last month',
    invoiceProFormPl: 'Invoice pro form (Polish)',
    invoiceProFormEn: 'Invoice pro forn (English)',
    dateOfIssue: 'Date of issue',
    seller: 'Seller',
    bankAccount: 'Bank account',
    buyer: 'Buyer',
    productServiceName: 'Name of product/service',
    bruttoPrice: 'Brutto price',
    bruttoWorth: 'Worth brutto',
    worth: 'Worth',
    inWords: 'In words',
    documentIssue: 'Document issued by',
    documentReceived: 'Document was received by',
    unitOfMeasure: 'u. of m.',
    bankName: 'Bank name',
    orderConfirmPrint: 'Order confirmition',
    default: 'Default',
    links: 'Links',
    selectCustomer: 'Select customer',
    noData: 'No data',
    allFiles: 'All files',
    databaseTranslation: 'Database translation',
    additional: 'Additional',
    new: 'New',
    reclamationDecisionProtocolPrint: 'Decision protocol',
    reclamationNotification: 'Reclamation notification',
    reclamationDecisionProtocol: 'Decision protocol',
    notificationDate: 'Notification date',
    salesPoint: 'Sales point',
    montage: 'Montage',
    toolbar: 'Toolbar',
    toolbarItems: 'Toolbar items',
    documentToPrint: {
      printDeliveryNote: 'Wydruk kwitu wagowego',
      printDeliveryNoteRoll: 'Wydruk kwitu wagowego (rolka)',
      printDeliveryNoteTurn: 'Wydruk kwitu wagowego ze skrętem',
    },
    reclamationServiceProtocolPrint: 'Service protocol',
    reclamationServiceProtocol: 'Service protocol',
    reclamationMileageProtocolPrint: 'Mileage protocol',
    reclamationMileageProtocol: 'Mileage protocol',
    productionOrders: 'Production orders',
    reclamationRegistrationProtocolPrint: 'Rejestration protocol',
    reclamationRegistrationProtocol: 'Registration protocol',
    settlementsData: 'Settlement data',
    describeAlgorithm: 'Describe algorithm',
  },
  components: {
    advancedSearch: 'Advanced Search',
    historyOfChanges: 'History of changes',
    linkedDocs: 'Related documents',
    relatedTasks: 'Related tasks.',
    subordination: 'Subordination structure',
    versionDetails: 'Details of the version',
    versionsCompare: 'Version comparison',
    viewSettings: 'View settings',
  },
  configurator: {
    byName: 'By name / description ...',
    byNumber: 'By No...',
    selectedParameters: 'Selected parameters',
  },
  customerRequest: {
    addComment: 'New comment',
    createNew: 'Create a query',
    edit: 'Edit bid inquiry no.',
    editResult: 'Edit result',
    editStatus: 'Change of status',
    editDecision: 'Edit decision',
    newStatus: 'Select new status',
    selectDecision: 'Select decision',
    newDecision: 'New decision',
    msg: {
      delete: 'The bid request will be marked for deletion!',
      emptyCustomer: 'An unfilled customer!',
      emptyReference: 'An unfilled reference!',
      noWrite: 'The bid request must be saved!',
      orderAvailable: 'A bid request with such a reference already exists: {0}',
      return: 'The bid request will be reinstated!',
      selectItemFirst: 'Choose to ask for an offer first!',
    },
    new: 'New inquiry',
    newVersion: 'New version of quote request',
    presentation: 'Request for Quotation No. {0} from {1}',
    title: 'Request for Quotation',
  },
  discountBelongs: {
    all: 'All',
    main: 'Basic price list',
    main_and_surcharge: 'Price list and surcharges',
    surcharge: 'Surcharges',
    surcharge_and_price: 'Surcharges and additional articles',
  },
  discountTypes: {
    formula: 'Formula',
    percent: 'Percentage',
    price: 'Price',
  },
  editTypes: {
    created: 'Added',
    deleted: 'Deleted',
    edited: 'Modified',
    markedToDeleteRestore: 'Marked for deletion / Restored',
  },
  email: {
    messages: 'Message',
    email: 'Email',
    emailSended: 'Email sended',
    otherFiles: 'Other files',
    other: 'Other',
    all: 'All',
    attachTo: {
      customerRequest: 'Customer request',
      interaction: 'Interaction',
      reclamation: 'Reclamation',
    },
    attachments: 'Attachments',
    productionFile: 'Production file',
    signed: 'Signed',
    bcc: 'UDW',
    body: 'Message',
    bodyPh: 'News Content.',
    signedByClient: 'Signed by client',
    cc: 'DW',
    contents: 'Content',
    create: {
      customerRequestCorrection: 'Correction of customer request',
      newCustomerRequest: 'New customer request',
      newInteraction: 'New interaction',
      newReclamation: 'New reclamation',
    },
    defaultEmailAccount: 'Default email account',
    drafts: 'Draft versions',
    emailMoved: 'The marked messages have been moved!',
    fillConterpartiesBegin: 'The system has started to fill in contractors (contact persons) to e-mail',
    fillConterpartiesEnd: 'The system has finished filling in the contractors (contact persons) for the e-mail',
    fillCounterparties: 'Fill customers / contacts in emails',
    fillCounterparty: 'Fill customer / contact person',
    forReceive: 'For reception',
    forSend: 'For shipping',
    from: 'from',
    imapHost: 'IMAP server',
    imapPort: 'IMAP port',
    imapTls: 'Use SSL/TLS',
    inbox: 'Recieved',
    recievedEmail: 'Recieved email: ',
    sentEmail: 'Sent email: ',
    labels: 'Labels',
    manageLabels: 'Manage labels',
    manageTemplates: 'Manage templates',
    moveTo: 'Move to ...',
    moveToJunk: 'Move to spam',
    moveToTrash: 'Move to garbage can',
    msg: {
      selectEmailFirst: 'No email was selected!',
    },
    newMessage: 'New message',
    noEmails: 'No e-mails',
    none: 'None',
    notProcessed: 'Not processed',
    onLineMode: 'Online mode',
    processed: 'Processed',
    response: 'Reply',
    responseAll: 'Reply to all',
    seen: 'Read',
    selectEmailAccount: 'Select an e-mail account',
    sent: 'Sent',
    setAs: 'Mark as',
    setLabel: 'Label it',
    setMailSignature: 'User email definitions',
    shown: 'Displayed',
    signatures: 'Signatures',
    smtpHost: 'SMTP Server',
    smtpPort: 'SMTP port',
    smtpTls: 'Use SSL/TLS',
    spam: 'Spam',
    storeReceived: 'Zapish received',
    storeSended: 'Zapish sent',
    subject: 'Subject',
    subjectPh: 'Message subject',
    title: 'Email message',
    to: 'to',
    toSend: 'To be sent',
    trash: 'Garbage can',
    unseen: 'Unread',
    withLinks: 'With links',
    withoutLinks: 'No links',
    writeSomething: 'Write something...',
  },
  enums: {
    INVENTORY_CATEGORY_TYPE: {
      income: 'Income',
      shipment: 'Shipping',
      transfer: 'Move',
    },
    OBJECT_TYPES: {
      catalog: 'Catalog',
      document: 'Document',
      register: 'Register',
      report: 'Report',
    },
    TEXT_POSITION: {
      begin: 'At the beginning',
      end: 'In the end',
      part: 'In part of the poem',
    },
    IMPORTANCES: {
      NOT_SET: 'No parameter',
      HIGHT: 'High',
      LOW: 'Low',
      NORMAL: 'The usual',
    },
    TYPE_OF_DOCUMENT: {
      Automobile: 'Automotive',
      Internal: 'Internal',
      Railway: 'Railroad',
      Water: 'Water',
    },
    TYPE_OF_OPERATION: {
      DirectVariantReceipt: 'Direct admission',
      DirectVariantShipment: 'Direct issue',
      Receipt: 'Adoption',
      Relocation: 'Offset',
      Resigning: 'Reformatting',
      Reweighing: 'Overweighting',
      Shipment: 'Issue',
    },
    VERSIONING_METHODS: {
      all: 'All operations',
      confirm: 'On approval',
      create: 'At the creation of',
      update: 'When updating',
    },
    VIEW_TYPES: {
      detail: 'Retail',
      list: 'List',
      other: 'Other',
      select: 'Selection',
    },
    changeTypes: {
      create: 'Adding',
      update: 'Change',
    },
    changedTypes: {
      create: 'He added',
      update: 'He changed',
    },
    contactTypes: {
      email: 'E-mail',
      messenger: 'Messenger',
      phone: 'Phone',
      skype: 'Skype',
      social: 'So',
    },
    navigationPlacings: {
      general: 'Important',
      other: 'Other',
      usual: 'The usual',
    },
    projectStatuses: {
      Finished: 'Finished',
      Ongoing: 'Ongoing',
    },
    statusList: {
      Finished: 'Closed',
      Ongoing: 'During',
    },
    transportTypes: {
      Car: 'Car',
      Trailer: 'Semi-trailer / Trailer',
    },
    typesOfMovement: {
      Expense: 'Outlet',
      Receipt: 'Revenue',
    },
    typesOfPayment: {
      Bank: 'Bank',
      Cash: 'Cash',
    },
    CURRENCY_EXCHANGE_TABLES: {
      A: 'A',
      B: 'B',
      C: 'C',
    },
    NOTIFICATION_TYPES: {
      recipient: 'Recipient',
      copy: 'Copy',
      notSend: 'Do not send',
    },
    ACCUMULATION_RECORD_TYPES: {
      expense: 'Expense',
      receipt: 'Receipt',
    },
    DELIVERY_NOTE_STATE: {
      OnBrutto: 'On brutto',
      OnTare: 'On tare',
      Returns: 'Return',
      Closed: 'Closed',
      ToApproved: 'To Approved',
    },
    CUSTOM_STATE_OF_GOODS: {
      EU: 'EU',
      NonEU: 'NonEU',
    },
  },
  errorLog: {
    qrCodeNotFound: 'Qr code not found',
  },
  fm: {
    actions: {
      add: 'Add',
      addColumn: 'Add column',
      addCorrespondence: 'Add correspondence',
      addItem: 'Add item',
      addOption: 'Add Option',
      addTab: 'Add Tab',
      cancel: 'Cancel',
      clear: 'Clear',
      code: 'Generate Code',
      confirm: 'Confirm',
      copyData: 'Copy Data',
      getData: 'Get Data',
      import: 'JSON import',
      json: 'Generate JSON',
      preview: 'Preview',
      reset: 'Reset',
      upload: 'Upload',
    },
    components: {
      advance: {
        title: 'Advance Component',
      },
      basic: {
        title: 'Basic Component',
      },
      fields: {
        blank: 'Custom',
        btnGroup: 'Btn group',
        button: 'Button',
        card: 'Card',
        cascader: 'Cascader',
        checkbox: 'Checkbox',
        collapse: 'Collapse',
        color: 'Color',
        container: 'Container',
        date: 'Date',
        divider: 'Divider',
        dropdown: 'Dropdown',
        editor: 'Editor',
        fileupload: 'File',
        grid: 'Grid',
        imgupload: 'Image',
        input: 'Input',
        inputGroup: 'Input group',
        number: 'Number',
        pagination: 'Pagination',
        radio: 'Radio',
        rate: 'Rate',
        select: 'Select',
        slider: 'Slider',
        switch: 'Switch',
        table: 'Sub-table',
        tabs: 'Tabs',
        text: 'Text',
        textarea: 'Textarea',
        time: 'Time',
        toolbar: 'Toolbar',
        'view-list': 'List',
      },
      layout: {
        title: 'Layout',
      },
    },
    config: {
      form: {
        actions: 'Actions',
        customClass: 'Custom Class',
        dataSource: 'Data source',
        functionDescription: 'Functional description',
        functionNaem: 'Function name',
        labelPosition: {
          left: 'Left',
          right: 'Right',
          title: 'Label Position',
          top: 'Top',
        },
        labelWidth: 'Label Width',
        methods: 'Features',
        size: 'Size',
        title: 'Form',
        toolbarContent: 'Toolbar content',
      },
      widget: {
        accordion: 'Accordion',
        action: 'Upload Address',
        align: 'Alignment',
        alignAround: 'Around',
        alignBaseline: 'Baseline',
        alignBetween: 'Between',
        alignBottom: 'Bottom',
        alignCenter: 'Center',
        alignContent: 'Content alignment',
        alignEnd: 'End',
        alignH: 'Horizontal alignment',
        alignMiddle: 'Middle',
        alignSelf: 'Align yourself',
        alignStart: 'Startup',
        alignStretch: 'Stretch',
        alignTop: 'Top',
        alignV: 'Vertical alignment',
        allowHalf: 'Allow Half',
        appear: 'Appear',
        array: 'Array',
        arrowControl: 'Use the arrow for time selection',
        attribute: 'Attribute Action',
        block: 'Block',
        bodyClass: 't-body style',
        boolean: 'Boolean',
        borderCard: 'Border-Card',
        bottom: 'Bottom',
        card: 'Tabs',
        center: 'Center',
        childrenOption: 'Sub-Option',
        clearable: 'Display Clear Button',
        color: 'Color',
        cols: 'cols',
        columnOption: 'Column positioning',
        contentPosition: 'Content Position',
        custom: 'Custom',
        customClass: 'Default styles',
        dataBind: 'Data Binding',
        date: 'Date',
        default: 'Default',
        defaultType: 'Data Type',
        defaultValue: 'Default Value',
        disabled: 'Disabled',
        dropleft: 'Drop left',
        dropright: 'Drop right',
        dropup: 'Drop up',
        editable: 'Text box is editable',
        email: 'E-mail',
        endPlaceholder: 'Placeholder of end time',
        fields: 'Columns',
        filterable: 'Searchable',
        float: 'Float',
        fluid: 'Filling',
        format: 'Format',
        height: 'Height',
        hex: 'Hexadecimal',
        hidden: 'Hidden',
        hover: 'Highlighting selected',
        imageAction: 'Picture upload address',
        inline: 'Inline',
        integer: 'Integer',
        isDelete: 'Deletable',
        isEdit: 'Editable',
        isQiniu: 'Upload with Qiniu Cloud',
        isRange: 'Range Time',
        isTimestamp: 'Get time stamp',
        itemsOption: 'Setting up the list',
        justify: 'Horizontal Arrangement',
        justifyCenter: 'Center',
        justifyEnd: 'End',
        justifySpaceAround: 'Space Around',
        justifySpaceBetween: 'Space Between',
        justifyStart: 'Startup',
        keyNav: 'Key navigation',
        label: 'Title',
        labelWidth: 'Label Width',
        layout: 'Layout',
        left: 'Left',
        lg: 'lg',
        max: 'Maximum',
        maxlength: 'Max length',
        md: 'md',
        min: 'Minimum',
        model: 'Model',
        multiple: 'Multiple',
        name: 'Name',
        newOption: 'New Option',
        noCaret: 'No caret',
        noFlip: 'No flip',
        noGutter: 'Disable grid spacing',
        number: 'Number',
        object: 'Object',
        offset: 'offset',
        option: 'Option',
        order: 'order',
        password: 'Password',
        patternPlaceholder: 'Fill in the regular expressions',
        perPage: 'Number of lines',
        pill: 'Pill',
        placeholder: 'Placeholder',
        range: 'Range',
        readonly: 'Read Only',
        remoteData: 'Remote Date',
        remoteFunc: 'Remote Function',
        required: 'Required',
        right: 'Right',
        rowCols: 'Columns in a row',
        rowColsLg: 'Columns in a row (Lg)',
        rowColsMd: 'Columns in a row (Md)',
        rowColsSm: 'Columns in a row (Sm)',
        rowColsXl: 'Columns in a row (Xl)',
        search: 'Search',
        selectMode: 'Selection mode',
        selectable: 'Selectable',
        showAlpha: 'Support transparency options',
        showInput: 'Display Input Box',
        showLabel: 'Show lable',
        showPassword: 'Display Password',
        showType: 'Display type',
        showWordLimit: 'Show word limit',
        size: 'Size',
        sm: 'sm',
        sortBy: 'Sort by',
        split: 'Split',
        squared: 'Squared',
        startPlaceholder: 'Placeholder of start time',
        staticData: 'Static Data',
        step: 'Step',
        string: 'String',
        striped: 'Striped',
        subTitle: 'Subtitle',
        tab: 'Tab',
        tabName: 'Tab Name',
        tabOption: 'Label Configuration',
        tabPosition: 'Tab Position',
        tabTitle: 'Attributes',
        tag: 'Tag',
        tel: 'Tel',
        text: 'Text',
        time: 'Time',
        tip: 'Text Prompt',
        title: 'Title',
        tokenFunc: 'A funchtin to get Qiniu Uptoken',
        top: 'Top',
        type: 'Type',
        uploadLimit: 'Maximum Upload Count',
        url: 'URL',
        validate: 'Validation',
        validatorPattern: 'Unmatched pattern',
        validatorRequired: 'Required',
        validatorType: 'Invaild format',
        value: 'Value',
        variant: 'Variant',
        variants: {
          danger: 'Danger',
          dark: 'Dark',
          info: 'Info',
          light: 'Light',
          outlineDanger: 'Outline danger',
          outlineDark: 'Outline dark',
          outlineInfo: 'Outline info',
          outlineLight: 'Outline light',
          outlinePrimary: 'Outline primary',
          outlineSecondary: 'Outline secondary',
          outlineSuccess: 'Outline success',
          outlineWarning: 'Outline warning',
          primary: 'Primary',
          secondary: 'Secondary',
          success: 'Success',
          warning: 'Warning',
        },
        vertical: 'Vertical',
        visible: 'Visible',
        width: 'Width',
        xl: 'xl',
      },
    },
    description: {
      configEmpty: 'Please add a component',
      containerEmpty: 'You can drag and drop the item from the left to add components',
      tableEmpty: 'You can drag and drop the item from the left to add components',
      uploadJsonInfo: 'There is the format of JSON below，you can overwrite it with you own JSON code',
    },
    message: {
      copySuccess: 'Copy Successed',
      deleteItem: 'The item will be marked for deletion!',
      restoreItem: 'The item will be restored!',
      validError: 'Form data validation failed',
    },
    upload: {
      delete: 'delete',
      edit: 'replace',
      preview: 'Preview',
    },
  },
  interaction: {
    addComment: 'New comment',
    addNewVersion: 'Add new version',
    actualStatus: 'Actual status',
    numberEdition: 'Interaction number edition',
    number: 'Interaction number',
    versionsHistory: 'History of versions',
    addEvent: 'New event',
    edit: 'Edit interaction no.',
    commentsAndLinkedEmails: 'Comments / Linked emails',
    tasksAndSalesOrders: 'Tasks / Sales orders',
    attachments: 'Interaction attachments',
    msg: {
      delete: 'The interaction will be marked for deletion!',
      emptyCustomer: 'An unfilled customer!',
      noWrite: 'The interaction must be recorded!',
      orderAvailable: 'Interaction with such a reference already exists: {0}',
      return: 'Interaction will be restored!',
      selectItemFirst: 'Choose interaction first!',
    },
    new: 'New interaction',
    newVersion: 'New version of interaction',
    presentation: 'Interaction No. {0} from {1}',
    selectFiles: 'Choose your files!',
  },
  login: {
    description: 'Enter your username and password to access the administration panel',
    forgotPassword: "Don't remember your password?",
    invalidLogin: 'Incorrectly entered user login (e-mail) or Password',
    logIn: 'Sign in',
    noHaveAccount: "Don't have an account?",
    password: 'Password',
    passwordPh: 'Enter password',
    register: 'Register',
    rememberMe: 'Remember me',
    securityCheck: 'Security check',
    sendNewCode: 'Send new code',
    authCode: 'Verification code',
    authCodePh: 'Enter verification code',
    invalidAuthCode: 'Incorrectly entered verification code',
    outOfDateCode: 'The verification code has expired. Please request a new one.',
    unexpectedError: 'An unexpected error occurred, try again',
    username: 'User',
    usernamePh: 'Enter your login',
  },
  msg: {
    authCodeSended: 'A new verification code has been sent',
    catalogWriteError: 'Failed to save directory item',
    catalogWriteSuccess: 'The directory element has been saved',
    documentConfirmError: 'Failed to confirm document',
    documentConfirmSuccess: 'Document has been confirmed',
    documentUndoConfirmError: 'Failed to undo document confirm',
    documentConfirmUndoSuccess: 'Document has been unconfirmed',
    createTrackingError: 'Failed to add object to tracking',
    createTrackingSuccess: 'The object has been added to the tracking',
    delete: 'The item will be marked for deletion!',
    deleteError: 'Item deletion error',
    filingError: 'Data is not correct',
    groupHasNestedItems: 'The group has subordinate elements',
    restore: 'The item will be restored!',
    technicalBreak: 'Technical break',
    noFoundResults: 'No results found for',
    startTypingForSearch: 'Start typing to search.',
    passwordConfirmationError: 'Passwords do not match',
    countryNotFilled: 'Country is not filled',
    viesTrue: 'Payer VAT-UE',
    viesFalse: 'Is not payer VAT-UE',
    documentWriteSuccess: 'The document has been saved',
    nothingFound: 'No results found',
    nothingSelected: 'Nothing selected',
    documentConfirmErrorMarekedToDelete: 'Failed to confirm document marked to delete',
  },
  navbar: {
    checkAll: 'Check all',
    clearAll: 'Clear all',
    profile: 'My profile',
    hello: 'Welcome!',
    logOut: 'Logout',
    newNotes: 'New notifications',
    notifications: 'Notifications',
  },
  navigation: {
    editRoute: 'Edit view',
    editSubsystem: 'Editing the subsystem',
    getPrezentation: 'Download the presentation from the repository',
    newPartition: 'New chapter',
    newRoute: 'New view',
    newSubsystem: 'New subsystem',
  },
  operators: {
    '!=': 'Not equal',
    '<': 'Smaller',
    '<=': 'Less than or equal to',
    '=': 'Equal',
    '>': 'Greater',
    '>=': 'Greater than or equal to',
    eq: 'Equal',
    gt: 'Greater',
    gte: 'Greater than or equal to',
    iLike: 'Includes',
    inList: 'On the list',
    like: 'Includes',
    lt: 'Smaller',
    lte: 'Less than or equal to',
    ne: 'Not equal',
    notILike: 'Does not include',
    notInList: 'Not on the list',
    notLike: 'Does not include',
  },
  order: {
    byOrderPeriod: 'According to the scope of the contract',
    createTask: 'Create a task',
    deliveryDays: 'Delivery days:',
    deliveryMethods: {
      ON_CERTAIN_DATE: 'For a specific date',
      STANDARD: 'Standard',
      UNTIL_CERTAIN_DATE: 'By a certain date',
      VIP: 'VIP',
    },
    enterDeliveryMethod: 'Choose the type of delivery',
    enterMaterial: 'Select material',
    enterProject: 'Enter the project name',
    enterReference: 'Enter reference',
    msg: {
      confirmDouble: 'An order with this reference already exists. Confirm that you would like to proceed to a quote?',
      confirmRemoveProduct: 'Are you sure you want to remove this product?',
      delete: 'The order will be marked for deletion!',
      emptyCurrency: 'Unfilled currency!',
      emptyCustomer: 'An unfilled customer!',
      emptyDeliveryMethod: 'An unfilled delivery method!',
      emptyFields: 'Not completed all mandatory fields!',
      emptyReference: 'An unfilled reference!',
      emptyStatus: 'Unfilled status!',
      orderAvailable: 'An order with such a reference already exists: {0}',
      return: 'The order will be reinstated!',
      selectOrderFirst: 'Choose your order first!',
      writeOrder: 'The order must be saved!',
      writed: 'The order was recorded',
    },
    new: 'New order',
    objectTitle: 'Order',
    packageMaterial: {
      Folia: 'Foil',
      Karton: 'Cardboard',
    },
    presentation: 'Order No. {0} from {1}',
    priceDetails: 'Price details',
    roadDays: 'Days on the Road:',
    shipmentDays: 'Departure days:',
    status: {
      CANCELED: 'Cancelled',
      CLOSED: 'Closed',
      CONFIRMED: 'Confirmed',
      NEW: 'New order',
      OFERTA: 'Offer',
      READY_FOR_PRODUCTION: 'Ready for production',
    },
    title: 'Customer orders',
  },
  paymentOperation: {
    createTask: 'Create a task',
    enterProject: 'Enter the project name',
    enterReference: 'Enter reference',
    msg: {
      confirmDouble: 'A payer operation with such a reference already exists. Confirm that you want to proceed with the quote?',
      delete: 'The payer operation will be marked for deletion!',
      emptyCurrency: 'Unfilled currency!',
      emptyCustomer: 'An unfilled customer!',
      emptyFields: 'Not completed all mandatory fields!',
      emptyReference: 'An unfilled reference!',
      orderAvailable: 'A payer operation with such a reference already exists: {0}',
      return: 'The payer operation will be restored!',
      selectOrderFirst: 'Choose an operation first!',
      writeOrder: 'The payer operation must be saved!',
      writed: 'The paymaster operation has been saved',
    },
    new: 'New operation',
    objectTitle: 'Payer operation',
    presentation: 'Paymaster operation no {0} from {1}',
    title: 'Payment operations',
  },
  pricelistKinds: {
    main: 'Basic',
    surcharge: 'Surcharge',
  },
  pricingMethods: {
    fixed: 'Fixed price',
    formula: 'Formula',
    percent: 'Percentage',
    scale: 'Price scale',
    table: 'Pricing table',
  },
  reclamation: {
    new: 'New complaint',
    add: 'Add complaint',
    addUpdatePositions: 'Adding / Editing a complaint order item',
    addOrder: 'Add order',
    productionJobNumber: 'Production job number',
  },
  register: {
    name: 'Name',
    firmTitle: 'Firm registration',
    surename: 'Surename',
    backHome: 'Back to home page',
    canResetPasswordMsg: 'You can now change your password.',
    confirm: 'Your account has been successfully registered',
    email: 'E-mail',
    emailCheck: 'Check the email from the portal and click the attached link to confirm the email.',
    emailConfirmed: 'E-mail confirmed',
    emailNotConfirmed: 'Failed to confirm email',
    emailPh: 'Enter your e-mail',
    emailSent: 'Email sent successfully. Please check your inbox.',
    emailSentError: 'An error occurred while trying to send an email. Please try again later.',
    enterDetailsPh: 'Enter your data',
    enterNewPassword: 'Enter a new password',
    enterPasswordLimit: 'Enter at least 7 characters.',
    enterValidEmail: 'Enter a valid email address',
    firstLastName: 'Name',
    haveAccount: 'Already have an account?',
    login: 'Login',
    loginPh: 'Enter your login',
    notFilledAllFilds: 'Not filled in all fields',
    passwordLinkSent: 'A link to change the password was sent to the indicated e-mail.',
    reenterEmail: 'Enter your email address again, for the new link.',
    reenterPassword: 'Enter password again',
    reenterPasswordError: 'It should be in accordance with the password entered.',
    register: 'Register',
    resendEmail: 'Resend e-mail',
    resendYourEmail: 'Resend your email',
    resetPasswordError: 'There was an error while processing your request. Please try resending the reset information again.',
    resetPasswordLate: 'It looks like you are a little too late. Try emailing again.',
    sendTo: 'The email was sent to',
    terms: 'Terms and conditions of use of the portal',
    title: 'User registration',
    titleDescription: "Don't have an account? Sign up, it will take less than a minute",
    notVatNumber: 'I do not have an intra-community VAT number',
    vatNumber: 'Intra-community VAT number',
    firmName: 'Firm name',
    firmNamePh: 'Enter firm name',
    country: 'Country',
    countryPh: 'Enter country',
    companyDepartment: 'Company department',
    companyDepartmentPh: 'Enter company department',
    commercialInChargeOfYou: 'Commercial in charge you',
    commercialInChargeOfYouPh: 'Enter commercial in charge you',
    phone: 'Phone number',
    phonePh: 'Enter phone number',
    vatNumberPh: 'Enter intra-community VAT number',
    manager: 'Manager',
    firmAddress: "Firm's address",
    firmAddressPh: "Enter firm's address",
    address: 'Address',
    addressPh: 'Enter address',
    postalCode: 'Postal code',
    postalCodePh: 'Enter postal code',
    city: 'City',
    cityPh: 'Enter city',
    deliveryDepartment: 'Delivery department',
    deliveryDepartmentPh: 'Enter delivery department',
  },
  report: {
    changeVariantName: 'Rename the report variant',
    columnName: 'Column name',
    deleteVariant: 'Remove report variant',
    filtered: 'filterable',
    filters: 'Filters',
    functional: 'functional',
    functions: {
      average: 'Medium',
      max: 'Maks',
      min: 'Min',
      sum: 'Total',
    },
    groupLvls: 'Grouping levels',
    grouped: 'groupable',
    groups: 'Grouping',
    msg: {
      periodControl: 'The beginning of the period cannot be greater than the end.',
    },
    newVariant: 'New variant of the report',
    onlyForThisUser: 'For this user only',
    resourses: 'Summarizing',
    selectedFields: 'Selected fields',
    total: 'Total',
    variants: 'Variants of the report',
    writeVariant: 'Save variant',
  },
  reports: {
    salesAnalize: 'Sales analysis',
    salesOrderRegister: 'Register of orders',
    interactionAnalize: 'Interaction analysis',
    inquiriesAnalize: 'Analize of inquiries',
  },
  route: {
    accessGroup: 'Rights group',
    accessGroups: 'Rights groups',
    accessRestriction: 'Restriction of access',
    accessRestrictions: 'Access restrictions',
    addUpdateProductionOrders: 'Add / Edit production order',
    action: 'Action',
    administration: 'Administering',
    appModule: 'System modul',
    appModules: 'System module',
    appObject: 'The object of the system',
    shipment: 'Shipment',
    addEditHistory: 'Add / Edit history',
    userProfile: 'User profile',
    appObjects: 'System objects',
    appSetting: 'Setting up the system',
    appSettingGroup: 'System settings group',
    appSettingItem: 'Definition of the system setting',
    appSettingItems: 'Definition of system settings',
    shippingOfComplaintItems: 'Shipment of complaintItems',
    financialPeriods: 'Financial periods',
    appSettings: 'System settings',
    assortment: 'Sorting',
    assortments: 'Sorts',
    bankExpense: 'Outgoing transfer',
    bankReceipt: 'Incoming transfer',
    box: 'Storage cell',
    timeTracking: 'Time tracking',
    boxes: 'Storage cells',
    bpAndTasks: 'Business processes and tasks',
    bpDefinition: 'Definition of business process',
    bpDefinitions: 'Definition of business processes',
    calendar: 'Calendar',
    carrier: 'Carrier',
    carriers: 'Carriers',
    cashExpense: 'The cashier will pay out',
    cashFlowItem: 'Cash flow component',
    cashFlowItems: 'Cash flow',
    cashReceipt: 'The cash register will accept',
    contactPerson: 'Contact person',
    contactPersons: 'Contact persons',
    controlCompanies: 'Inspection companies',
    controlCompany: 'Inspection company',
    costArticle: 'Type of costs',
    costArticles: 'Types of costs',
    costomer: 'Customer',
    costomers: 'Customers',
    counterparties: 'Contractors',
    counterparty: 'Contractor',
    countries: 'Countries',
    country: 'Country',
    crStatuse: 'Status of customer request',
    crStatuses: 'Customer request statuses',
    crTag: 'Tag of inquiry',
    crTags: 'Inquiry tags',
    currencies: 'Currencies',
    currency: 'Currency',
    customer: 'Customer Data',
    customerRequest: 'Customer request',
    customerRequests: 'Customer requests',
    dashboard: 'Daszbord',
    dashboards: 'Dashboards',
    deliveryNotes: 'Waybills',
    deliveryOrders: 'Orders',
    deliveryType: 'Type of shipping',
    deliveryTypes: 'Types of shipping',
    department: 'Department',
    departments: 'Departments',
    discount: 'Production discount',
    discounts: 'Production discounts',
    dispositionStatuse: 'Disposition status',
    dispositionStatuses: 'Disposition statuses',
    dispositions: 'Dispatches',
    documentNumerators: 'Document numberers',
    documentPrefixes: 'Document prefixes',
    driver: 'Driver',
    drivers: 'Drivers',
    emailAccount: 'Email account',
    emailAccounts: 'Email accounts',
    emailDetails: 'E-mail',
    emailLabel: 'Email label',
    emailLabels: 'Email labels',
    emailTemplates: 'Email templates',
    employee: 'Worker',
    employees: 'Employees',
    event: 'Event',
    eventStatus: 'Event status',
    eventStatuses: 'Event statuses',
    eventType: 'Type of event',
    eventTypes: 'Types of events',
    events: 'Events',
    exchangeObjects: 'Objects to be replaced',
    executor: 'Task Contractor',
    executorRole: 'The role of the contractor',
    executorRoles: 'The roles of contractors',
    executors: 'Contractors of tasks',
    firm: 'Company',
    firms: 'Companies',
    formBuilder: 'Form builder',
    forms: 'Views',
    interaction: 'Interaction',
    interactionStatuses: 'Interaction statuses',
    interactionTags: 'Interaction tags',
    interactions: 'Interactions',
    inventoryCategories: 'Inventory categories',
    inventoryOperationTypes: 'Inventory operation types',
    inventoryOperationType: 'Inventory operation type',
    inventoryDocuments: 'Inventory',
    inventoryStatuses: 'Inventory statues',
    logistic: 'Logistics',
    mailbox: 'Mailbox',
    main: 'Main',
    mine: 'Mine',
    mines: 'Mines',
    navigation: 'Setting up navigation',
    newEmail: 'New e-mail',
    numerators: 'Document numberers',
    objectVersioningSetting: 'Setting up object versioning',
    objectVersioningSettings: 'Object versioning settings',
    orderSubject: 'Item ordered',
    orderSubjects: 'Items ordered',
    otherSettings: 'Other settings',
    outgoingEmailDetails: 'Email sent',
    paymentType: 'Type of payment',
    paymentTypes: 'Types of payments',
    position: 'Position',
    positions: 'Items',
    pricelist: 'Production price list',
    pricelists: 'Production price lists',
    product: 'Position data',
    production: 'Production',
    products: 'Products',
    project: 'Project',
    projects: 'Projects',
    reclCatalogs: 'Dictionaries',
    reclDecisions: 'Decisions',
    reclExecutions: 'Implementation',
    reclPerpetrators: 'Responsible for complaints',
    reclReasons: 'Reasons for complaints',
    reclStates: 'Order statuses',
    reclSubjects: 'Subjects of orders',
    reclTypes: 'Types of orders',
    reclamation: 'Complaint',
    reclamationClientDemand: 'Customer expectation',
    reclamationClientDemands: 'Customer expectations',
    reclamationDecision: 'Decision',
    reclamationDecisions: 'Decisions',
    reclamationExecutionType: 'Type of order execution',
    reclamationExecutionTypes: 'Types of order execution',
    reclamationPerpetrator: 'Responsible for complaints',
    reclamationPerpetrators: 'Responsible for complaints',
    reclamationReason: 'The reason for the complaint',
    reclamationReasons: 'Reasons for complaints',
    reclamationStatistic: 'Complaint statistics',
    reclamationStatus: 'Status of orders',
    reclamationStatusGroup: 'Status group',
    reclamationStatusGroups: 'Status groups',
    reclamationStatuses: 'Order statuses',
    reclamationSubject: 'Subject of orders',
    reclamationSubjects: 'Subjects of orders',
    reclamationType: 'Type of orders',
    reclamationTypes: 'Types of orders',
    reclamations: 'Complaints',
    reclamationviewform: 'Complaint (preview)',
    registrationprotocol: 'Registration protocol',
    report: 'Report',
    reports: 'Reports',
    sales: 'Sales',
    salesOrder: 'Customer order',
    salesOrderStatuses: 'Customer order statuses',
    salesOrders: 'Customer orders',
    salesPoints: 'Points of sale',
    scale: 'Weight',
    scaleProtocol: 'Weight protocol',
    scaleProtocols: 'Weighing protocols',
    scales: 'Scales',
    scalesCars: 'Vehicle scales',
    schemeOfCargo: 'Transshipment relationship',
    schemesOfCargo: 'Transshipment relations',
    settings: 'Settings',
    ship: 'Ship',
    ships: 'Ships',
    standing: 'Employee function',
    standings: 'Functions of employees',
    statusGroups: 'Status groups',
    task: 'Task',
    taskTemplate: 'Task template',
    taskTemplates: 'Task templates',
    tasks: 'Tasks',
    termsOfPayment: 'Payment terms',
    unitOfMeasure: 'Unit of measure',
    unitsOfMeasure: 'Units of measurement',
    user: 'User',
    userRole: 'User role',
    userRoles: 'User roles',
    userSettingGroup: 'User settings group',
    userSettingItem: 'Definition of user setting',
    userSettingItems: 'Definition of user settings',
    userSettings: 'User settings',
    users: 'Users',
    usersAndAccess: 'Users and permissions',
    usersList: 'List of users',
    usersSettings: 'User settings',
    vatRates: 'VAT rates',
    vehicle: 'Mode of transport',
    vehicles: 'Means of transport',
    vendorsAndCustomers: 'Suppliers and customers',
    viewSetting: 'Setting the view',
    viewSettings: 'View settings',
    warehouse: 'Magazine',
    warehouses: 'Magazines',
    weighingStation: 'Weighing station',
    weighingStations: 'Weighing stations',
    workingPlaces: 'Workplaces',
    accessLogs: 'Access logs',
    exchangeRates: 'Exchange rates',
    exchangeRate: 'Exchange rate',
    loadingPlan: 'Loading plan',
    loadingPlans: 'Loading plans',
    storagePeriods: 'Storage periods',
    storagePeriod: 'Storage period',
    salesPrice: 'Sales price',
    salesPrices: 'Sales prices',
    serviceAgreement: 'Service agreement',
    serviceAgreements: 'Service agreements',
    shipRegistration: 'Ship registration',
    shipRegistrations: 'Ship registrations',
    shipLoading: 'Ship loading',
    shipLoadings: 'Ship loadings',
    serviceCharge: 'Service charge',
    serviceCharges: 'Service charges',
    work: 'Work',
    works: 'Work',
    equipments: 'Equipment',
    equipment: 'Equipment',
    worksheet: 'Worksheet',
    worksheets: 'Worksheets',
  },
  settings: {
    lsbThemeDark: 'Dark',
    lsbThemeDefault: 'Default',
    lsbThemeLight: 'Bright',
    lsbTypeCondensed: 'Curled',
    lsbTypeFixed: 'Full',
    lsbTypeScrollable: 'Scrollable',
    title: 'Settings',
  },
  ships: {
    imo: 'International maritime organization',
  },
  table: {
    counterpartyAccountingGroup: 'Counterparty accounting groups',
    productAccountingGroup: 'Product accounting group',
    abbreviation: 'Abbreviation',
    accessRole: 'The role of access',
    actions: 'Shares',
    actualWarehouse: 'Place of foundation',
    phoneNumber: 'Phone number',
    added: 'Attached',
    saleDate: 'Sale date',
    result: 'Result',
    address: 'Address',
    adminAccess: 'Admin access',
    advicesNumber: 'Advice number / composition',
    internalComment: 'Internal comment',
    constructionAddress: 'Construction address',
    correspondenceAddress: 'Correspondence address',
    reason: 'Reason',
    subject: 'Subject',
    agreement: 'Contract',
    allowRetaring: 'Allow retargeting',
    image: 'Image',
    bidSum: 'Bid amount',
    role: 'Role',
    allowSetZero: 'Allow zero receipt',
    alphaCode2: 'Alpha-2 code',
    responsibility: 'Responsibility',
    numberOfWeighings: 'Number of weighings',
    minutesCarService: 'Time of car service',
    alphaCode3: 'Alpha-3 code',
    amountsType: 'Amount type',
    api: 'Api',
    appObject: 'The object of the system',
    trackTime: 'Total time for current approach',
    manual: 'manual',
    totalTime: 'Total time',
    time: 'Time',
    approved: 'Accepted',
    approvedAuthor: 'Author of the party',
    execution: 'Execution',
    approvedDate: 'Date of adoption',
    arrivalDateDriver: 'Date and time of arrival at the parking lot',
    productionOrder: 'Production order',
    numbers: 'Numbers',
    protocol: 'Protocol',
    arriveFrom: 'Arrival from',
    article: 'Article',
    assortment: 'Sorting',
    assortmentGmpPLus: 'GMP+ Sorting',
    attentionCount: 'Number of comments',
    author: 'Author',
    automaticWeightingEnd: 'Automatic termination of weighing',
    barCode: 'Barcode',
    barCodePrefix: 'Barcode prefix',
    base: 'Basis',
    baseDocument: 'Document basis',
    basicDocument: 'Basic document',
    productionQuantity: 'Production quantity',
    basicItem: 'Basic element',
    beginDate: 'Start date',
    beginWeighting: 'Start of weighing',
    belongs: 'Refers to',
    brutto: 'Gross',
    bruttoAuthor: 'Gross author',
    bruttoTime: 'Gross weight date',
    bruttoWeight: 'Gross weight',
    btsStatement: 'BTS statement',
    calendarEvent: 'Calendar Event',
    cancelWeighting: 'Cancel weighing',
    capasity: 'Capacity',
    carBrand: 'Make of car',
    carNumber: 'Car number',
    carQueueStatus: 'Status of the car queue',
    carServiceTime: 'Car service time',
    carriageNumber: 'Wagon number',
    carrier: 'Carrier',
    carsBuffer: 'Car buffer',
    carsQueueStatus: 'Status of the car queue',
    cashFlowItem: 'Cash flow el.',
    category: 'Category',
    changeDate: 'Date of change',
    changeType: 'Type of editing',
    checkExecution: 'Check the workmanship',
    checker: 'Checker',
    city: 'City',
    client: 'Customer',
    clientId: 'Customer ID',
    closeDate: 'Closing date',
    closed: 'Closed',
    code: 'Code',
    code1C: 'Code 1C',
    color: 'Color',
    comPort: 'COM port',
    comment: 'Comments',
    comments: 'Comments',
    commission: 'Commission, %',
    component: 'Component',
    computerName: 'Computer name',
    confirmed: 'Verified',
    constr: 'Constructor',
    constructor: 'Constructor',
    contact: 'Contact',
    contactPerson: 'Contact person',
    containerNumber: 'Container number',
    contents: 'Content',
    contractor: 'Contractor',
    controlCompany: 'Inspection company',
    correspondence: 'Correspondence',
    counterparty: 'Contractor',
    country: 'Country',
    createdAt: 'Created',
    currency: 'Currency',
    currencyStr: 'Currency (Str)',
    customGoodsStatus: 'Customs status of goods',
    customer: 'Customer',
    customerAccess: 'Available for customer selection',
    customerLockOrder: 'Block order for customer',
    customersGroup: 'Customer group',
    date: 'Date',
    dateOfBirth: 'Date of birth',
    dateOfTest: 'Date of survey',
    days: 'Days',
    declaredTare: 'Declared tare',
    declaredTonnage: 'Declared tonnage',
    default: 'Default',
    defaultWarehouse: 'Basic storage',
    delete: 'Removal',
    deliveryDate: 'Delivery date',
    costs: 'Costs',
    positionDescription: 'Position description',
    shipmentAddress: 'Shippment address',
    whoPays: 'Who pays',
    shipmentType: 'Shipment type',
    packageNumber: 'Package number',
    daysSinceAdmission: 'Days since admission',
    deliveryMethod: 'Shipping option',
    deliveryNote: 'Waybill',
    deliveryNoteNumber: 'Waybill number',
    deliveryOrder: 'Order',
    deputy: 'Deputy',
    descComment: 'Comment',
    description: 'Description',
    desktopMode: 'Desktop Mode (AMP)',
    desktopName: 'Desktop Name (AMP)',
    detailPath: 'Path to the editing element',
    dimensionX: 'X axis',
    dimensionY: 'Y axis',
    disableControlInDispositions: 'Turn off checks on dispatches',
    disableControlOnScales: 'Turn off checks at the scales',
    discount: 'Discount',
    discountPercent: '% discount',
    discountSum: 'Amount of discount',
    discountType: 'Type of discount',
    discription: 'Description',
    disposition: 'Disposition',
    dispositionAuthor: 'Dispatches were issued by',
    doNotLoadToOracle: 'Do not upload to Oracle',
    docType: 'Type',
    documentType: 'Type of document',
    done: 'Done',
    driver: 'Driver',
    driverPhoneNumber: "Driver's phone number",
    driverTicket: "Driver's ticket",
    email: 'E-mail',
    emailAccount: 'Email account',
    employee: 'Worker',
    endDate: 'Completion date',
    endOfWeighting: 'End of weighing',
    endTime: 'The end of time',
    endWeighting: 'End of weighing',
    entryTicket: 'Entrance ticket',
    enumerator: 'Counter',
    exchangeRate: 'Exchange rate',
    exchangeRateDate: 'Exchange rate date',
    exchangeRateTableNumber: 'Exchange rate table number',
    executionPeriod: 'Execution date',
    executionTerm: 'Execution date',
    executor: 'Contractor',
    executorName: 'Contractor name',
    executorRole: 'The role of the contractor',
    expireDate: 'Expiration date',
    externalCode: 'External code',
    externalId: 'External identifier',
    field: 'Field',
    fileSize: 'Size, kB',
    filters: 'Filters',
    firstWeighting: 'First weigh-in',
    forServices: 'For services',
    formula: 'Formula',
    forwarder: 'Forwarder',
    from: 'From',
    fullName: 'Full name',
    guarantee: 'Guarantee',
    handlers: 'Developments',
    hasLinks: 'There are links',
    hashParam: 'Hash parameter (#)',
    hidden: 'Hidden',
    howToContact: 'How to contact',
    icon: 'Icon',
    id: 'Id',
    idCard: 'ID card',
    idNumber: 'Proof number',
    importance: 'Priority',
    includedInMain: 'Included in the basic',
    incomingDate: 'Document date',
    incomingNumber: 'Incoming number',
    initDate: 'Start date',
    inquiry: 'Inquiry',
    isActive: 'Active',
    isCardNumber: 'Proof number',
    isCardSerial: 'Proof series',
    isClosed: 'Closed',
    isGeneral: 'General',
    isService: 'Service',
    issuanceRailwayInstructions: 'Issuance of railroad dispatches',
    item: 'Merchandise',
    jOM: 'J.M.',
    key: 'Key',
    lang: 'Translation',
    language: 'Language',
    lastNumber: 'Last issue',
    leader: 'Manager',
    links: 'Links',
    loadCapacity: 'Carrying capacity',
    lockArrivesFrom: 'Blocking arrival from',
    main: 'Main',
    manager: 'Merchant',
    markedToDelete: 'Marked for removal',
    max: 'Max',
    maxDiscount: 'Maximum discount',
    maxLoss: 'Max cavity',
    min: 'Min',
    mine: 'Mine',
    model: 'Model',
    modify: 'Edit',
    moving: 'Offset',
    multiple: 'Multiple',
    name: 'Name',
    netWeight: 'Net weight',
    netto: 'Net',
    nettoAuthor: 'Net author',
    nettoOverAll: 'Net general, T',
    nettoRegistered: 'Net registered, T',
    nettoTime: 'Net weight date',
    networkPort: 'Network port',
    nip: 'NIP',
    notUse: 'Do not use',
    notUseDiscount: "Don't use discounts",
    note: 'Note',
    noFilters: 'No filters',
    notes: 'Note',
    number: 'Number',
    numberAndDateDSKAndMRN: 'Number , date DSK/MRN',
    numberAndDateDsk: 'Number , date DSK',
    numberCopies: 'Number of copies',
    numberOfCopies: 'Number of copies',
    numberStr: 'Number (Str)',
    object: 'Subject',
    objectDescription: 'Description of the object',
    objectId: 'Object ID',
    objectType: 'Type of object',
    offer: 'Offer',
    operationType: 'Type of operation',
    operator: 'Condition',
    order: 'Order',
    orderAuthor: 'The order was issued by',
    orderNumber: 'Order No.',
    ordered: 'Ordered',
    orientation: 'Orientation',
    packageMaterial: 'Packaging material',
    paramValues: 'Parameter meanings',
    parent: 'It belongs to',
    parkingAriveDateAndTime: 'Date and time of arrival at the parking lot',
    password: 'Password',
    path: 'Track',
    fieldTypePath: 'Path track',
    multiType: 'Multi type',
    paymentTerms: 'Payment terms',
    paymentType: 'Type of payment',
    period: 'Period',
    phone: 'Phone',
    placing: 'Disposition',
    port: 'Port',
    position: 'Position',
    postCode: 'Zip code',
    constructionPostCode: 'Construction post code',
    postDate: 'Date of dispatch',
    predefinedName: 'Name defined',
    prefix: 'Prefix',
    price: 'Price',
    priceWithoutVAT: 'Net price',
    priceCode: 'Price list code',
    priceType: 'Price type',
    pricelistKind: 'Price list type',
    pricesType: 'Price type',
    pricingMethod: 'Valuation method',
    printerList: 'List of printers',
    printerName: 'Printer name',
    priority: 'Priority',
    processed: 'Processed',
    product: 'Product',
    proforma: 'Pro-Forma',
    progressValue: 'Progress',
    prohibitionWorkSeveralSessions: 'Prohibition of working in a clique of sessions',
    project: 'Project',
    ptotocols: 'Protocols',
    quantity: 'Quantity',
    queryParam: 'Query parameter (?)',
    queueOn: 'In line',
    read: 'Preview',
    readOnly: 'Read only',
    recipient: 'Recipient',
    reference: 'Reference',
    reflect: 'Reflect',
    region: 'District',
    registry: 'Register',
    responsible: 'Responsible',
    resultEmail: 'E-mail',
    retaring: 'Retargeting',
    returnToWarehouse: 'Back to the warehouse',
    reverseChange: 'Reverse change',
    reverseOperation: 'Reverse operation',
    rollPrinter: 'Roller printer',
    salesOrder: 'Customer order',
    scale: 'Weight',
    scale2: 'Weight 2',
    scaleBrutto: 'Gross weight',
    scaleNetto: 'Net weight',
    scaleProtocol: 'Weight protocol',
    scaleTare: 'Tare weight',
    scaleTwo: 'Weight 2',
    scalesType: 'Type of weight',
    schemeOfCargo: 'Relation',
    searchField: 'Search field',
    secondWeighting: 'Second weighing',
    selectProtocol: 'Protocol selection',
    sendCommand: 'Send command',
    sendDriversSms: 'Send text messages to drivers',
    sendingDate: 'Date of dispatch',
    sent: 'Sent',
    server: 'Server',
    sex: 'Gender',
    ship: 'Ship',
    shipmentDate: 'Date of shipment',
    signature: 'Signature',
    singularName: 'Single name',
    singularTitle: 'Single title',
    size: 'Rormiar',
    sort: 'Lp',
    sortId: 'Order',
    sortNumber: 'N',
    sortNumberFull: 'Sequence number',
    startDate: 'Start date',
    startOfWeighting: 'Start of weighing',
    startTime: 'Start time',
    state: 'Status',
    station: 'Position',
    status: 'Status',
    statusStr: 'Status (Str)',
    statusgroup: 'Status group',
    step: 'Step',
    store: 'Repository',
    storeFilesToHardDrive: 'Save files to your hard drive',
    subExecutor: 'Subcontractor',
    sum: 'Amount',
    sumBrutto: 'Gross Amount',
    sumBruttoTotalItems: 'Gross Amount (sum)',
    priceNational: 'Price (national)',
    sumNetNational: 'Net Amount (national)',
    sumGrossNational: 'Gross Amount (national)',
    sumVATNational: 'VAT amount (national)',
    sumNetto: 'Net Amount',
    sumNettoTotalItems: 'Net Amount (sum)',
    sumPayment: 'Payment amount',
    sumVAT: 'VAT amount',
    sumVat: 'VAT amount',
    sumWithVAT: 'Gross Amount',
    sumWithoutVAT: 'Net Amount',
    supplier: 'Supplier',
    symbol: 'Symbol',
    tags: 'Tags',
    tare: 'Tara',
    tareAuthor: 'Author tare',
    tareTime: 'Tare weight date',
    task: 'Task',
    tasks: 'Tasks',
    taxRate: 'The amount of tax',
    template: 'Template',
    termsOfPayment: 'Payment term',
    termsOfUse: 'Consent to user terms and conditions',
    tests: 'Research',
    title: 'Title',
    to: 'To',
    topic: 'Subject',
    trailer: 'Trailer',
    trainNumber: 'Train number',
    type: 'Type',
    typeOfDocument: 'Document type',
    typeOfMovement: '',
    typeOfOperation: 'Type of operation',
    typeOfPayment: '',
    typeParse: 'Parsing type',
    updatedAt: 'Update date',
    use: 'Use',
    useActualWarehouse: 'Use the actual warehouse',
    useDepartmentRestriction: 'Use the restriction by department',
    useProductCondition: 'Use product status',
    useRestriction: 'Use general restrictions',
    user: 'User',
    userAccess: 'User access',
    usingVideoRecorder: 'Use of a video recorder',
    uuid: 'UUID',
    value: 'Meaning',
    valueType: 'Value type',
    vatAmount: 'Deposit amount',
    vatRate: 'VAT rate',
    paymentMethod: 'Method of payment',
    paymentDate: 'Date of payment',
    vehicle: 'Car number',
    vehicleWagon: 'Wagon number',
    vendor: 'Supplier',
    version: 'Version',
    versionUuid: 'Version UUID',
    versioningMethod: 'Versioning method',
    videoRecorder: 'Video recorder',
    view: 'View',
    viewType: 'View type',
    visible: 'Visible',
    wagon: 'Wagon',
    warehouse: 'Location',
    warehouseFrom: 'The warehouse from',
    warehouseSection: 'Storage sector',
    warehouseSquare: 'Warehouse/Space',
    warehouseTo: 'Magazine to',
    weighingStation: 'Weighing station',
    weightingType: 'Weighing type',
    yearNumber: 'Year',
    averagePrice: 'Average price',
    grossAmount: 'Value (gross)',
    lineNumber: 'Nr',
    reclamationNumber: 'Reclamation number',
    clientName: 'Client name',
    telephone: 'Phone',
    deliveryStatus: 'Delivery state',
    amount: 'Amount',
    deliveryAddress: 'Delivery address',
    salesDate: 'Sales date',
    bankAccount: 'Bank account number',
    useCustomerAccess: 'Use customer access',
    ip: 'IP',
    method: 'Method',
    url: 'URL',
    query: 'Query',
    params: 'Parametr',
    body: 'Body',
    headers: 'Headers',
    euMember: 'EU member',
    onlySelected: 'Only selected',
    rate: 'Rate',
    multiplier: 'Multiplier',
    rateDate: 'Rate date',
    activeDate: 'Active date',
    table: 'Currency exchange table',
    tableNumber: 'NBP table number',
    regon: 'REGON',
    krs: 'KRS',
    payerVat: 'VAT payer',
    payerVies: 'Payer VAT-UE',
    message: 'Message',
    possitionWh: 'Possition WH',
    items: 'Items',
    itemsReady: 'Items ready for collection',
    cost: 'Cost',
    orders: 'Orders',
    serviceOrders: 'Service orders',
    selectedOrders: 'Selected orders',
    supplierReclamations: 'Reclamation to supplier',
    reclamationOrderNumber: 'Order number',
    activitiesDescription: 'Description of activities',
    arrivalDate: 'Arrival date',
    departureDate: 'Departure date',
    loadingStart: 'Loading start',
    loadingEnd: 'Loading end',
    dockNumber: 'Dock number',
    destinationPort: 'Destination port',
    plannedDockingTime: 'Planned docking time',
    arrivalDraft: 'Arrival draft',
    departureDraft: 'Departure draft',
    afterLoadingDraft: 'After loading draft',
    forwardDraft: 'Forward draft',
    aftDraft: 'Aft draft',
    forwardDraftDeparture: 'Forward draft departure',
    aftDraftDeparture: 'Aft draft departure',
    closingDate: 'Closing date',
    cargoNumber: 'Cargo number',
    captain: 'Captain',
    destination: 'Destination',
    sender: 'Sender',
    agent: 'Agent',
    holds: 'Holds',
    hold: 'Hold',
    counterparties: 'Counterparties',
    recipients: 'Recipients',
    service: 'Service',
    serviceChargesServices: 'Basic services',
    serviceAgreementsServices: 'Basic services',
    material: 'Material',
    pricelist: 'Pricelist',
    storagePeriod: 'Storage period',
    mail: 'Mail',
    notificationType: 'Notification type',
    work: 'Work',
    equipment: 'Equipment',
    workQuantity: 'Work quantity',
    hoursQuantity: 'Hours quantity',
    workDateFrom: 'Work from',
    workDateTo: 'Work to',
    shiftOrders: 'Shift orders',
    dateFrom: 'Date from',
    dateTo: 'Date to',
    workShifts: 'Work shift',
    shipped: 'Shipped',
    batches: 'Batches',
    exporters: 'Exporters',
    exporter: 'Exporter',
    shipLoading: 'Ship loading',
    shipRegistration: 'Ship registration',
    algorithm: 'Algorithm',
    hour: 'Hours.',
    shipIn: 'Arrive',
    shipOut: 'Departure',
    shipStart: 'Loading start',
    shipStop: 'Loading end',
    totalEn: 'Total',
    totalAll: 'Total',
    breaks: 'Breaks',
    foreman: 'Foreman',
    warehouseShipPrint: 'Load distribution by warehouse/placement',
    equipmentShipPrint: 'Crane numbers',
    shipRegistrationPrint: 'Transshipment report',
    holdsPrint: 'Holds',
    confirmation: 'Confirmation',
    remained: 'Remained',
    remainedEn: 'Remained',
    tonnage: 'Tonnage',
    workingPlace: 'Working place',
    function: 'Function',
    equipmentNumber: 'Equipment number',
    itemNumber: 'Pos.',
    orderNum: 'Order',
    shemeOfCargoNum: 'Rel.',
    transportQuantity: 'Transport quantity',
    akord: 'Akord',
    daily: 'Daily',
    totalHours: 'Total hours',
    worksheet: 'Worksheet',
    workersQuantity: 'Workers quantity',
    invoiceItems: 'Invoice items',
    summary: 'Summary',
    batch: 'Batch',
    chargedServices: 'Charged services',
    numberOfDays: 'Number of days',
    mainToolbar: 'Main toolbar',
    height: 'Height',
    depth: 'Depth',
    grossWeight: 'Gross Weight',
    volume: 'Volume',
    dimensions: 'Dimensions',
    weightAndVolume: 'Weight and volume',
    owner: 'Owner',
    basic: 'Basic',
    inventoryBatches: 'Inventory batches',
    box: 'Storage cell',
    periodicity: 'Periodicity',
    numberFormat: 'Number format',
    monthDay: 'Day of the month',
    monthNumber: 'Month number',
    quarterNumber: 'Quarter number',
    yearTwoDigits: 'Year (2 Digits)',
    yearFourDigits: 'Year (4 Digits)',
    organization: 'Organization',
    CounterpartyCode: 'Contractor code',
    storeCode: 'Store code',
    separator: 'Separator',
    productVariant: 'Product variant',
    positions: 'Positions',
    additionalProducts: 'Additional products',
    reservations: 'Reservations',
    taras: 'Taras',
    wastes: 'Wastes',
    forReception: 'Warehouse for reception',
    externalData: 'External data',
    externalUsername: 'External base username ',
    externalPassword: 'Password',
    externalServer: 'Server',
    externalPort: 'Port',
    externalDatabase: 'Database name',
    externalDialect: 'Dialect',
    groupChange: 'Group change',
    use2FA: 'Two-factor authentication',
    fullRights: 'Full rights',
    externalDateKind: 'Date kind',
    externalDateFormat: 'Date format',
    recorder: 'Recorder',
    settlementDocument: 'Services charged',
    typeOfCounterparty: 'Counterparty type',
    typeOfLocation: 'Location type',
  },
  tagsView: {
    close: 'Close',
    closeAll: 'Close All',
    closeOthers: 'Close Others',
    refresh: 'Refresh',
  },
  task: {
    description: 'Task description',
    executionReceive: 'Take for execution',
    executionResult: 'The result of performing the task',
    forExecutor: 'For the contractor',
    forExecutorRole: 'For the role of the contractor',
    newTask: 'New task',
    redirection: 'Task redirection',
    showExecuted: 'Show made',
    showMyne: 'Show my',
    showTasks: 'Show tasks',
  },
  theme: {
    leftSidebar: 'Left bar',
  },
  valueTypes: {
    boolean: 'Logical',
    date: 'Date',
    number: 'Number',
    ref: 'Reference',
    string: 'Text',
  },
  weekDays: {
    Fr: 'Pt',
    Mo: 'Pn',
    Sa: 'Sb',
    Su: 'Nd',
    Th: 'Thurs',
    Tu: 'Wt',
    We: '¶',
  },
  excelEditor: {
    first: 'First',
    previous: 'Previous',
    next: 'Next',
    last: 'Last',
    footerRight: {
      selected: 'Selected:',
      filtered: 'Filtered:',
      loaded: 'Loaded:',
    },
    processing: 'Processing',
    tableSetting: 'Table Setting',
    exportExcel: 'Export Excel',
    importExcel: 'Import Excel',
    back: 'Back',
    reset: 'Default',
    sortingAndFiltering: 'Sorting And Filtering',
    sortAscending: 'Sort Ascending',
    sortDescending: 'Sort Descending',
    near: '≒ Near',
    exactMatch: '= Exact Match',
    notMatch: '≠ Not Match',
    greaterThan: '&gt; Greater Than',
    greaterThanOrEqualTo: '≥ Greater Than or Equal To',
    lessThan: '&lt; Less Than',
    lessThanOrEqualTo: '≤ Less Than Or Equal To',
    regularExpression: '~ Regular Expression',
    customFilter: 'Custom Filter',

    apply: 'Apply',
    noRecordIsRead: 'No record is read',
    readonlyColumnDetected: 'Readonly column detected',

    noMatchedColumnName: 'No matched column name',
    invalidInputValue: 'Invalid input value',
    missingKeyColumn: 'Missing key column',
    noRecordIndicator: 'No record',
  },
}
