import router from '@/router'
import axios from 'axios'
import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { RouteConfig } from 'vue-router'
import { INavigationItem } from '@/store/types/NavigationType'
import { IAppState } from '@/store/types/AppType'
import { getRouteFromNavItem, prepareRouteForRouter } from '@/utils/routes-service'
import { allRoutes, navRoutes } from '@/router/staticRoutes'
import i18n from '@/lang'
import _ from 'lodash'
import Vue from 'vue'
import packege from '@/../package.json'

const initState: IAppState = {
  info: {},
  allRoutes: allRoutes,
  navRoutes: navRoutes,
  desktopMode: getCookiesDesktopMode(),
  desktopName: getCookiesDesktopName(),
  enums: {},
}

export const state: IAppState = Object.assign({}, _.cloneDeep(initState))

export const mutations: MutationTree<IAppState> = {
  setInfo(state: IAppState, payload: RouteConfig[]) {
    state.info = payload
  },

  setAllRoutes(state: IAppState, payload: RouteConfig[]) {
    state.allRoutes = allRoutes.concat(payload)
  },

  setNavRoutes(state: IAppState, payload: RouteConfig[]) {
    state.navRoutes = navRoutes.concat(payload)
  },

  setDesktopMode(state: IAppState, payload: boolean) {
    state.desktopMode = payload
    saveState('app.desktopMode', payload)
  },

  setDesktopName(state: IAppState, payload: string) {
    Vue.set(state, 'desktopName', payload)
    saveState('app.desktopName', payload)
  },

  setEnums(state: IAppState, payload: string) {
    Vue.set(state, 'enums', payload)
  },

  resetState(state: IAppState) {
    Object.assign(state, _.cloneDeep(initState))
  },
}

export const getters: GetterTree<IAppState, any> = {
  allRoutes(state: IAppState) {
    return state.allRoutes
  },

  navRoutes(state: IAppState) {
    return state.navRoutes
  },

  desktopMode(state: IAppState) {
    return state.desktopMode
  },

  desktopName(state: IAppState) {
    return state.desktopName
  },

  enums(state: IAppState) {
    return state.enums
  },
}

export const actions: ActionTree<any, any> = {
  async init({ dispatch }) {
    await dispatch('getNavigation')
    await dispatch('setSessionParams')
    await dispatch('getEnums')
    await dispatch('getAppInfo')
  },

  async getObjects(context, payload: any) {
    return axios
      .get(`/app/objects`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error
      })
  },

  async getObjectMeta(context, payload: any) {
    return axios
      .get(`/app/object_meta`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error
      })
  },

  async getAppInfo(context, payload: any) {
    return axios
      .get(`/app/info`, payload)
      .then((response) => {
        const appInfo = { ...response.data }
        appInfo.clientVersion = packege.version

        context.commit('setInfo', appInfo)
        return response
      })
      .catch((error) => {
        return error
      })
  },

  async execServerFunc(context, payload: any) {
    return axios
      .post(`/app/exec-func`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error
      })
  },

  async hasRole(context, payload: any) {
    return axios
      .get(`/app/has-role`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error
      })
  },

  async getRegistersMetadata(context, payload: any) {
    return axios
      .get(`/app/registers_meta`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error
      })
  },

  async getNavigation({ commit }) {
    const lang = i18n.locale

    return axios
      .get(`/navigation`, { params: { lang } })
      .then((response) => {
        const allRoutes: RouteConfig[] = []
        const navRoutes: RouteConfig[] = []

        if (response.status === 200) {
          response.data.forEach((navElement: INavigationItem) => {
            const optionNavigation = getRouteFromNavItem(navElement, 1)

            if (navElement.isMenu === true) {
              navRoutes.push(optionNavigation)
            }

            allRoutes.push(optionNavigation)
          })
        }

        commit('setAllRoutes', allRoutes)
        commit('setNavRoutes', navRoutes)

        const routerRoutes = _.cloneDeep(allRoutes) as Array<RouteConfig>

        for (const rote of routerRoutes) {
          prepareRouteForRouter(rote)
        }

        addRoutesToRouter(routerRoutes)

        return allRoutes
      })
      .catch((error) => {
        throw error
      })
  },

  async getSetting(context, payload: any) {
    return axios
      .get(`/app/setting`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error
      })
  },

  async setSetting(context, payload: any) {
    return axios
      .post(`/app/setting`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error
      })
  },

  async setSessionParams({ commit, dispatch }) {
    if (process.env.IS_ELECTRON) {
      const clientIdSerial = await window.ipcRenderer.invoke('getSerialIdClient', '')

      if (clientIdSerial) {
        await dispatch(
          'workingPlaces/findAll',
          {
            params: {
              filter: {
                clientIdSerial,
              },
            },
          },
          { root: true }
        )
          .then((response: any) => {
            if (response.status === 200 && response.data[0]) {
              const workingPlace = response.data[0]

              if (workingPlace?.desktopMode !== null) {
                commit('setDesktopMode', workingPlace.desktopMode)
              }

              if (workingPlace?.desktopName) {
                commit('setDesktopName', workingPlace.desktopName)
              }
            }
          })
          .catch((error: any) => {
            console.error(error)
          })
      }
    }
  },

  async getEnums({ commit }) {
    const lang = i18n.locale

    return axios
      .get(`/app/enums`, { params: { lang } })
      .then((response) => {
        commit('setEnums', response.data)
        return response
      })
      .catch((error) => {
        commit('setEnums', {})
        throw error
      })
  },

  resetState({ commit }) {
    commit('resetState')
  },
}

function getCookiesDesktopMode(): boolean {
  const desktopMode = window.localStorage.getItem('app.desktopMode')
  return desktopMode ? JSON.parse(desktopMode) : false
}

function getCookiesDesktopName(): string | null {
  const desktopName = window.localStorage.getItem('app.desktopName')
  return desktopName ? JSON.parse(desktopName) : null
}

function saveState(key: string, state: string | boolean | null) {
  window.localStorage.setItem(key, JSON.stringify(state))
}

function addRoutesToRouter(routes: Array<any>) {
  for (const route of routes) {
    const link = router.resolve({ path: route.path })

    if (link.route.name === '404') {
      const { children, ...vRoute } = route
      router.addRoute(vRoute)
    }

    if (route.children) {
      addRoutesToRouter(route.children)
    }
  }
}
