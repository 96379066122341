import Vue from 'vue'
import './axiosConfig'
import './registerServiceWorker'
import router from './router'
import store from './store'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

import i18n from '@/lang'
import Vuelidate from 'vuelidate'
import VueExcelEditor from 'vue-excel-editor'
import VueLayers from 'vuelayers'
import VueSelects from 'vue-select'
import Select from '@/components/common/select.vue'
import FiltersManager from '@/components/common/filters-manager/index.vue'
import vco from 'v-click-outside'
import '@/components/_globals'
import CodeDiff from 'v-code-diff'
import VueQRCodeComponent from 'vue-qrcode-component'

import dateFilter from '@/filters/date-filter'
import upperCaseFilter from '@/filters/upper-case-filter'
import lowerCaseFilter from '@/filters/lower-case-filter'
import numberFilter from '@/filters/number-filter'
import languageFilter from '@/filters/language-filter'

import * as VueGoogleMaps from 'vue2-google-maps'

import VueCryptojs from 'vue-cryptojs'



const googleApiKey = 'AIzaSyAjRAwgtT45K1wqP0BsRuNszdLIh4mC7xM' //TODO

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY ? process.env.VUE_APP_GOOGLE_API_KEY : '',
    libraries: 'places,geometry',
  },
})

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/pl'
  ; ('vue2-datepicker/locale/fr')
  ; ('vue2-datepicker/locale/en')
  ; ('vue2-datepicker/locale/nl')
  ; ('vue2-datepicker/locale/de')

Vue.filter('date', dateFilter)
Vue.filter('upper', upperCaseFilter)
Vue.filter('lower', lowerCaseFilter)
Vue.filter('number', numberFilter)
Vue.filter('language', languageFilter)

Vue.config.productionTip = false
Vue.use(VueExcelEditor)
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(vco)
// eslint-disable-next-line
Vue.use(require('vue-moment'))
Vue.use(require('vue-uuid'))

Vue.use(CodeDiff)
Vue.use(VueCryptojs)

// Set the components prop default to return our fresh components
VueSelects.props.components.default = () => ({
  OpenIndicator: {
    render: (createElement: any) => createElement('span', ''),
  },
})

Vue.component('DatePicker', DatePicker)
Vue.component('VSelect', VueSelects)
Vue.component('FSelect', Select)
Vue.component('qr-code', VueQRCodeComponent)
Vue.component('filters-manager' , FiltersManager)


// register all VueLayers components
Vue.use(VueLayers, {
  // global data projection, see https://vuelayers.github.io/#/quickstart?id=global-data-projection
  // dataProjection: 'EPSG:4326',
})

process.on('unhandledRejection', (error) => {
  console.error(error)
})

const appEnums = {
  install(Vue: any, options: any) {
    Vue.prototype.$enums = (enumName: string | undefined = undefined, value: string | undefined = undefined) => {
      const mainStore = store.state as any

      if (!enumName) {
        return mainStore['app'].enums
      }

      const enumInfo = mainStore['app'].enums.find((el: any) => el.name === enumName)

      if (enumInfo) {
        if (value) {
          const enumValue = enumInfo.values.find((el: any) => {
            return el.value === value
          })

          return enumValue ? enumValue.title : value
        } else {
          return enumInfo.values.map((el: any) => {
            return { value: el.value, text: el.title }
          })
        }
      } else {
        return value ? value : []
      }
    }
  },
}

Vue.use(appEnums)

const appObjects = {
  install(Vue: any, options: any) {
    Vue.prototype.$appObjects = (appObjectName: string | undefined = undefined) => {
      const mainStore = store.state as any

      if (!appObjectName) {
        return mainStore['app'].appObjects
      }

      const appObjectInfo = mainStore['app'].appObjects.find((el: any) => el.name === appObjectName)

      return appObjectInfo
    }
  },
}

Vue.use(appObjects)

const serverFunc = {
  install(Vue: any, options: any) {
    Vue.prototype.$serverFunc = async function (objectFunc: any, params: any) {
      if (objectFunc.split('.').length === 1) {
        if ((this as any)?.name === 'CommonPrint') {
          if ((this as any).printSettings?.name) {
            objectFunc = `printForms.${(this as any).printSettings?.name}.${objectFunc}`
          }
        }
      }
      return await store.dispatch('app/execServerFunc', { objectFunc, params })
    }
  },
}

Vue.use(serverFunc)

const hasRole = {
  install(Vue: any, options: any) {
    Vue.prototype.$hasRole = async (role: string, accessType: string) => {
      const response = await store.dispatch('app/hasRole', {
        params: { role, accessType },
      })
      if (response.status === 200) {
        return response.data
      } else {
        return false
      }
    }
  },
}

Vue.use(hasRole)

const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')

declare global {
  interface Window {
    Cypress?: any
    app?: any
    ipcRenderer?: any
  }
}

if (window.Cypress) {
  // only available during E2E tests
  window.app = app
}
