import customerRequestsActions from './customerRequests-actions'

export default {
  executeConditionCode(str, args) {
    const conditionFunction = new Function('args', str)
    const conditionRes = conditionFunction(args)

    return conditionRes
  },

  async executeAsyncConditionCode(str, args, $store) {
    const objectActions = {
      customerRequestsActions,
    }
    const AsyncFunction = Object.getPrototypeOf(async function () {}).constructor
    const asyncFn = new AsyncFunction('args, $store, objectActions', str)
    const result = await asyncFn(args, $store, objectActions)

    return result
  },

  async executeTask($store, payload) {
    const taskResult = await $store.dispatch('tasks/executeTask', payload)
    return taskResult
  },

  async executeAction($store, payload) {
    const data = { ...payload }
    const isManual = Boolean(data.isManual && !data.currentUser?.externalUser && data.currentUser?.fullRights)
    const users = await $store.dispatch('users/findAll', { noCommit: true }).then((res) => res.data)
    const statuses = await this.getOwnerObjStatuses($store, data.ownerType)
    const ownerObj = await this.getOwnerObj($store, data)

    let actionResponse = null
    if (!isManual) {
      // Execute action script.
      actionResponse = await this.executeAsyncConditionCode(
        data.action.script,
        {
          [data.ownerType]: ownerObj,
          executors: users,
          statuses,
        },
        $store
      )

      if (data.action?.allowChangeExecutor && data.customExecutor) {
        actionResponse.executorId = data.customExecutor?.id
      }

      data.actionResponse = actionResponse
    }

    const currentStageHistory = $store.state.bpHistories.histories.find((history) => {
      const stage = JSON.parse(history.stage)
      if (history.exemplarId === ownerObj.bpExemplar.id && stage.id === ownerObj.bpExemplar.stage.id && !history.finishedAt) return true
    })
    data.bpHistoryId = currentStageHistory.id

    let nextStage = null
    if ((isManual || data.action?.allowChangeStage) && data.customStageId) {
      nextStage = this.findStageById(data.customStageId, ownerObj)
    } else {
      const nextStageId = await this.findNextStageId(actionResponse, ownerObj, $store)
      nextStage = this.findStageById(nextStageId, ownerObj)
    }

    if (isManual || data.action?.allowChangeExecutor) {
      nextStage = { ...nextStage, valueType: 'user', value: data.customExecutor.id }
    }
    data.nextStage = nextStage

    const res = await $store.dispatch('bpExemplars/executeAction', data)

    if(res.status !== 200) {
      return 'error'
    }

    if(!nextStage) {
      return 'not_found_next_stage'
    }

    return 'success'
  },

  async executeActionOld($store, payload) {
    const users = await $store.dispatch('users/findAll', { noCommit: true }).then((res) => res.data)
    const statuses = await this.getOwnerObjStatuses($store, payload.ownerType)
    const ownerObj = await this.getOwnerObj($store, payload)
    const currentUser = payload.currentUser
    const myTask = ownerObj.tasks.find((task) => task.executorId === currentUser.id)
    const isManual = Boolean(payload.isManual && !currentUser?.externalUser && currentUser?.fullRights)
    const storeName = this.getStoreName(payload.ownerType)
    let actionResponse = null

    if (isManual) {
      // Cancel all tasks of current stage.
      await Promise.all(
        ownerObj.tasks.map(async (task) => {
          return await this.executeTask($store, { id: task.id, executionResult: 'canceled' })
        })
      )
    } else {
      if (myTask?.id) {
        await this.executeTask($store, { id: myTask.id, executionResult: payload.executionResult || '' })
      }

      // if there isn't my task or the task isn't connected bp or there are other started tasks, then can't either execute action or go to next stage.
      if (!isManual && (!myTask?.id || !myTask?.bpDefinition || ownerObj.tasks?.length > 1)) return

      // Execute action script.
      actionResponse = await this.executeAsyncConditionCode(
        payload.action.script,
        {
          [payload.ownerType]: ownerObj,
          executors: users,
          statuses,
        },
        $store
      )

      if (payload.action?.allowChangeExecutor && payload.customExecutor) {
        actionResponse.executorId = payload.customExecutor?.id
      }

      // Update owner object.
      await $store.dispatch(`${storeName}/update`, {
        id: ownerObj.id,
        updateData: actionResponse,
      })
    }

    // Finish current stage.
    const currentStageHistory = $store.state.bpHistories.histories.find((history) => {
      const stage = JSON.parse(history.stage)
      if (history.exemplarId === ownerObj.bpExemplar.id && stage.id === ownerObj.bpExemplar.stage.id && !history.finishedAt) return true
    })

    await this.updateHistory($store, currentStageHistory.id)

    let nextStage = null
    if ((isManual || payload.action?.allowChangeStage) && payload.customStageId) {
      nextStage = this.findStageById(payload.customStageId, ownerObj)
    } else {
      const nextStageId = await this.findNextStageId(actionResponse, ownerObj, $store)
      nextStage = this.findStageById(nextStageId, ownerObj)
    }

    if (isManual || payload.action?.allowChangeExecutor) {
      nextStage = { ...nextStage, valueType: 'user', value: payload.customExecutor.id }
    }

    if (nextStage) {
      await this.updateBpExemplarStage($store, nextStage, ownerObj, storeName, payload.ownerType)
      // Add history
      await this.addHistory($store, ownerObj, nextStage)
    } else {
      return 'not_found_next_stage'
    }
  },

  getStoreName(ownerType) {
    let storeName = ''
    switch (ownerType) {
      case 'customerRequest':
        storeName = 'customerRequests'
        break
      case 'reclamation':
        storeName = 'reclamations'
        break
      default:
        break
    }

    return storeName
  },

  findStageById(id, ownerObj) {
    const stage = ownerObj.bpExemplar.definition.stages.array.find((e) => e.uuid === id)
    return stage
  },

  async findNextStageId(actionResponse, ownerObj, $store) {
    let nextStageId = ownerObj.bpExemplar.stage?.nextStage
    const nextStage = this.findStageById(nextStageId, ownerObj)

    if (nextStage?.isCondition) {
      const args = {
        customerRequest: { ...ownerObj, ...actionResponse },
      }

      for (const cond of nextStage.conditions) {
        const res = await this.executeAsyncConditionCode(cond.conditionCode, args, $store)
        if (res === true) {
          nextStageId = cond.nextStage
          break
        }
      }

      //   nextStage.conditions.every(async (cond) => {
      //     const res = await this.executeAsyncConditionCode(cond.conditionCode, args, $store)
      //     if (res) {
      //       nextStageId = cond.nextStage
      //       return false
      //     }
      //     return true
      //   })
    }

    if (nextStageId === nextStage.uuid && nextStage?.isCondition) {
      return null
    }
    return nextStageId
  },

  async getOwnerObj($store, { ownerType, ownerId }) {
    const storeName = this.getStoreName(ownerType)
    if (!storeName) return null

    const response = await $store.dispatch(`${storeName}/findByPk`, { noCommit: true, params: { id: ownerId } })

    return response.data || null
  },

  async getOwnerObjStatuses($store, ownerType) {
    let storeName = ''
    switch (ownerType) {
      case 'customerRequest':
        storeName = 'customerRequestStatuses'
        break
      case 'reclamation':
        storeName = 'reclamations'
        break
      default:
        break
    }

    if (!storeName) return null

    const response = await $store.dispatch(`${storeName}/findAll`, { noCommit: true })
    return response.data || null
  },

  async addHistory($store, ownerObj, stage) {
    let executorId = null
    if (stage.valueType === 'user') {
      executorId = stage.value
    }

    if (stage.valueType === 'main_object') {
      executorId = ownerObj[stage.value]
    }
    const history = {
      stage: JSON.stringify(stage),
      exemplarId: ownerObj.bpExemplar.id,
      authorId: executorId,
    }

    await $store
      .dispatch('bpHistories/addHistory', history)
      .then((res) => {
        if (res) {
          console.log('history inserted')
        }
      })
      .catch((err) => console.error(err))
  },

  // update finishedAt
  async updateHistory($store, id) {
    const history = {
      id,
      finishedAt: new Date(),
    }

    $store.dispatch('bpHistories/updateHistory', history).catch((err) => console.error(err))
  },

  // update next stage and add new task
  async updateBpExemplarStage($store, nextStage, ownerObj, storeName, ownerType) {
    await $store.dispatch('bpExemplars/updateItem', {
      id: ownerObj.bpExemplar.id,
      stage: JSON.stringify(nextStage),
    })

    if (nextStage?.isFinal) {
      return
    }

    const executorRoleId = nextStage.valueType === 'executor_role' ? nextStage.value : null
    let executorId = null
    if (nextStage.valueType === 'user') {
      executorId = nextStage.value
    }

    if (nextStage.valueType === 'main_object') {
      executorId = ownerObj[nextStage.value]
    }

    const newTaskParams = {
      ownerType: ownerType,
      ownerId: ownerObj.id,
      bpDefinition: ownerObj.bpExemplar.definitionId,
      customerId: ownerObj.customerId,
      nextStage: nextStage.text,
      lang: nextStage.lang?.text ? { name: nextStage.lang?.text } : null,
      nextStageId: nextStage.id,
      nextExecutorId: executorId,
      nextExecutorRoleId: executorRoleId,
      started: true,
    }

    await $store.dispatch('tasks/createTaskBasingOnManualRedirection', newTaskParams)

    //need to think how to redesign this
    await $store.dispatch(`${storeName}/update`, {
      id: ownerObj.id,
      updateData: {
        executorId: executorId,
      },
    })
  },
}
