import axios from 'axios'
import store from '@/store'
import CloneDeep from 'lodash/cloneDeep'
import { ActionContext } from 'vuex'
import { IState } from '@/store/service/state'
import { commonState } from '@/store/service/state'
import { commonMutations } from '@/store/service/mutations'
import { commonGetters } from '@/store/service/getters'
import { rawQuery } from '@/utils/commonUse'
import {
  findAll,
  findByPk,
  findPredefined,
  createItem,
  updateItem,
  confirmItem,
  undoItemConfirm,
  deleteItem,
  addNewItem,
  changeDeletionMark,
  resetState,
} from '@/store/service/actions'

interface actionsObj {
  [key: string]: any
}

interface functions {
  [key: string]: any
}

interface events {
  [key: string]: any
}

export default async function addDynamicModules() {
  if (!store.state?.auth?.currentUser) {
    return
  }

  await axios
    .get('/app_objects', { params: { filter: { markedToDelete: false } } })
    .then((response) => {
      if (response.data) {
        const appObjects = response.data

        for (const appObject of appObjects) {
          if (!store.hasModule(appObject.name)) {
            let mutations = { ...commonMutations }
            let getters = { ...commonGetters }
            let functions: functions = {
              rawQuery: (queryData: any) => rawQuery(queryData),
            }
            let events: events = {}

            let actions: actionsObj = {
              async findAll(context: ActionContext<IState, any>, payload: any) {
                return findAll(context, appObject.path, payload, events.beforeGetList)
              },

              async findByPk(context: ActionContext<IState, any>, payload: any) {
                return findByPk(context, appObject.path, payload, events.beforeGetObject)
              },

              async findPredefined(context: ActionContext<IState, any>, payload: any) {
                return findPredefined(context, appObject.path, payload, events.beforeGetPredefined)
              },

              async create(context: ActionContext<IState, any>, payload: any) {
                return createItem(context, appObject.path, payload, events.beforeCreate)
              },

              async update(context: ActionContext<IState, any>, payload: any) {
                return updateItem(context, appObject.path, payload, events.beforeUpdate)
              },

              async delete(context: ActionContext<IState, any>, payload: any) {
                return deleteItem(context, appObject.path, payload, events.beforeDelete)
              },

              async changeDeletionMark(context: ActionContext<IState, any>, payload: any) {
                return changeDeletionMark(context, appObject.path, payload, events.beforeChangeDM)
              },

              async addNew(context: ActionContext<IState, any>, payload: any) {
                if (appObject.type === 'document') {
                  if (typeof payload === 'string') {
                    payload = {
                      id: payload,
                    }
                  }

                  payload.date = new Date()
                }

                return addNewItem(context, { appObject }, payload, events.beforeAddNew)
              },

              async getSelectFields(context: ActionContext<IState, any>, payload: any) {
                if (appObject.type === 'catalog') {
                  if(payload){
                    return [{ key: payload.label, label: `table.${payload.label}`, sortable: true }]
                  }
                  return [{ key: 'name', label: 'table.name', sortable: true }]
                }

                return [{ key: 'presentation', label: 'table.name', sortable: true }]
              },

              resetState(context: ActionContext<IState, any>) {
                resetState(context)
              },
            }

            if (appObject.type === 'document') {
              actions.confirm = async (context: ActionContext<IState, any>, payload: any) => {
                return confirmItem(context, appObject.path, payload, events.beforeConfirm)
              }
              actions.undoConfirm = async (context: ActionContext<IState, any>, payload: any) => {
                return undoItemConfirm(context, appObject.path, payload, events.beforeUndoConfirm)
              }
            }

            if (appObject.module && appObject.module !== '') {
              try {
                const moduleData = eval(`(${appObject.module})`)

                if (moduleData) {
                  if (moduleData.events) {
                    events = Object.assign(events, CloneDeep(moduleData.events))
                  }

                  if (moduleData.functions) {
                    functions = Object.assign(functions, CloneDeep(moduleData.functions))
                  }

                  if (moduleData.mutations) {
                    mutations = Object.assign(mutations, CloneDeep(moduleData.mutations))
                  }

                  if (moduleData.actions) {
                    actions = Object.assign(actions, CloneDeep(moduleData.actions))
                  }

                  Object.keys(actions).forEach((actionKey) => {
                    const originalAction = actions[actionKey]
                    actions[actionKey] = async function (context: any, payload: any) {
                      try {
                        return await originalAction({ axios, ...context }, payload)
                      } catch (error) {
                        console.error(error)
                      }
                    }
                  })

                  if (moduleData.getters) {
                    getters = Object.assign(getters, CloneDeep(moduleData.getters))
                  }
                }
              } catch (error) {
                console.error(error)
              }
            }

            store.registerModule(appObject.name, {
              state: () => Object.assign({}, CloneDeep(commonState)),
              mutations,
              actions,
              getters,
              functions,
              events,
              namespaced: true,
            })
          }
        }
      }
    })
    .catch((error) => {
      console.error(error)
    })
}
