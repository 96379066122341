import axios from 'axios'
import { uuid } from 'vue-uuid'
import _ from 'lodash'

export default {
  async sendSmsTemplateDispositionStart(view, dispositionObject) {
    return await axios
      //.post(`outgoing_sms/send_sms_template_disposition_start`, { dispositionId: dispositionObject.id })
      .post(`dispositions/send_start_sms`, dispositionObject)
      .then(async (response) => {
        if (response.status === 200) {
          return { message: 'Zrobione!' }
        } else {
          return { message: 'Nie udało się wykonać. Spróbuj ponownie trochę później!' }
        }
      })
      .catch((error) => {
        console.error(error)
        return { message: 'Nie udało się wykonać. Spróbuj ponownie trochę później!' }
      })
  },

  async sendSmsTemplateDispositionDelete(view, dispositionObject) {
    // const dataSms = this.getDataNewOutgoingSms(dispositionObject)
    return await axios
      // .post(`outgoing_sms/send_sms_template_disposition_delete`, dataSms)
      .post(`outgoing_sms/send_sms_template_disposition_delete`, { dispositionId: dispositionObject.id })
      .then(async (response) => {
        if (response.status === 200) {
          return { message: 'Zrobione!' }
        } else {
          return { message: 'Nie udało się wykonać. Spróbuj ponownie trochę później!' }
        }
      })
      .catch((error) => {
        console.error(error)
        return { message: 'Nie udało się wykonać. Spróbuj ponownie trochę później!' }
      })
  },

  async sendSmsTemplateDispositionAllowEntrySms_Old(view, dispositionObject, amountPay = 0) {
    if (this.checkNeedSendSms(view, dispositionObject.forwarderId) === false) {
      return
    }
    let smsBody = dispositionObject.driver.presentation
    if (amountPay <= 0) {
      smsBody += '\nMozesz jechac na terminal \nMasz 20 min. na wyjazd.'
    } else {
      smsBody += '\nMusisz rozliczyć się w infokiosku! \nPo opatach masz 20 min. na wyjazd.'
    }

    this.outgoingSmsCreate(view, dispositionObject, smsBody)
  },

  async sendSmsTemplateDispositionAllowEntrySms(view, dispositionObject, amountPay = 0) {
    // const dataSms = this.getDataNewOutgoingSms(dispositionObject)
    // dataSms.amountPay = amountPay
    return await axios
      // .post(`outgoing_sms/send_sms_template_disposition_allow_entry`, dataSms)
      .post(`outgoing_sms/send_sms_template_disposition_allow_entry`, { dispositionId: dispositionObject.id })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.result === true) {
            return { message: 'Zrobione!' }
          } else {
            return { message: 'Nie udało się wykonać. Spróbuj ponownie trochę później!' }
          }
        } else {
          return { message: 'Nie udało się wykonać. Spróbuj ponownie trochę później!' }
        }
      })
      .catch((error) => {
        console.error(error)
        return { message: 'Nie udało się wykonać. Spróbuj ponownie trochę później!' }
      })
  },

  async sendSmsTemplateAuthCode(dataSms) {
    await axios.post(`outgoing_sms/send_sms_template_auth_code`, { dataSms: dataSms }).catch((error) => {
      throw error
    })
  },

  async sendSmsTemplateDispositionAllowEntryMainParking_Old(view, dispositionObject) {
    if (this.checkNeedSendSms(view, dispositionObject.forwarderId) === false) {
      return
    }
    const shortOrderNumber = this.getShortOrderNumber(dispositionObject.order.numberStr)
    let smsBody =
      dispositionObject.driver.presentation +
      '\nDysp. ' +
      dispositionObject.numberStr +
      '\nZlec. ' +
      shortOrderNumber +
      '\nMożesz wjechać na Parking Chrzanowskiego.'
    if (dispositionObject.carsQueueStatus === 'EntryAddParking' || dispositionObject.carsQueueStatus === 'Paused') {
      smsBody += '\nMasz 10 minut na wyjazd.'
    }

    this.outgoingSmsCreate(view, dispositionObject, smsBody)
  },

  async sendSmsTemplateDispositionAllowEntryMainParking(view, dispositionObject) {
    // const dataSms = this.getDataNewOutgoingSms(dispositionObject)
    return await axios
      .post(`outgoing_sms/send_sms_template_disposition_allow_entry_main_parking`, { dispositionId: dispositionObject.id })
      .then(async (response) => {
        if (response.status === 200) {
          return { message: 'Zrobione!' }
        } else {
          return { message: 'Nie udało się wykonać. Spróbuj ponownie trochę później!' }
        }
      })
      .catch((error) => {
        console.error(error)
        return { message: 'Nie udało się wykonać. Spróbuj ponownie trochę później!' }
      })
  },

  getDataNewOutgoingSms(dispositionObject) {
    return {
      dispositionId: dispositionObject.id,
      vehicleId: dispositionObject.vehicle.id,
      driverId: dispositionObject.driver.id,
      sent: false,
      recipients: [
        {
          id: uuid.v4(),
          parentId: dispositionObject.id,
          rowNumber: 1,
          phoneNumber: dispositionObject.driverPhoneNumber,
          name: dispositionObject.driver.name,
          sent: false,
        },
      ],
    }
  },

  async checkNeedSendSms(view, forwarderId) {
    let result = false
    if (!forwarderId) {
      return result
    }
    await view.$store
      .dispatch('vendorsAndCustomers/findByPk', { noCommit: true, params: { id: forwarderId } })
      .then(async (response) => {
        if (response.data) {
          if (response.data.sendDriversSms) {
            result = response.data.sendDriversSms
          }
        }
      })
      .catch((error) => {
        console.error(error)
      })

    return result
  },

  getShortOrderNumber(fullOrderNumber) {
    let shortOrderNumber = ''
    const slashPosition = fullOrderNumber.indexOf('/')
    if (slashPosition > 0) {
      shortOrderNumber = fullOrderNumber.substring(0, slashPosition - 1)
    }
  },

  smsCut(smsBody) {
    let result = smsBody
    if (result.length > 160) {
      result = result.substring(0, 159)
    }
    return result
  },

  async outgoingSmsCreate(view, dispositionObject, smsBody) {
    const smsBodyAfterCutting = this.smsCut(smsBody)

    const dataSms = {
      body: smsBodyAfterCutting,
      dispositionId: dispositionObject.id,
      vehicleId: dispositionObject.vehicle.id,
      driverId: dispositionObject.driver.id,
      sent: false,
      recipients: [
        {
          id: uuid.v4(),
          parentId: dispositionObject.id,
          rowNumber: 1,
          phoneNumber: dispositionObject.driverPhoneNumber,
          name: dispositionObject.driver.name,
          sent: false,
        },
      ],
    }
    await view.$store
      .dispatch('outgoingSms/create', dataSms)
      .then(async (response) => {
        if (response.status === 200) {
          // await view.$store
          //   .dispatch('outgoingSms/findByPk', { noCommit: true, params: { id: response.data.id } })
          //   .then(async (response2) => {
          //     if (response2.status === 200) {
          //       dataSms.id = response2.data.id
          //       dataSms.updatedAt = new Date()
          //       await view.$store.dispatch('outgoingSms/update', dataSms).then(async (response3) => {
          //         if (response3.status === 200) {
          //           await this.outgoingSmsSend(view, response3.data)
          //         }
          //       })
          //     }
          //   })
          //   .catch((error) => {
          //     console.error(error)
          //     return
          //   })

          await this.outgoingSmsSend(view, response.data)
        }
      })
      .catch((error) => {
        console.error(error)
        return
      })
  },

  async outgoingSmsSend(view, outgoingSmsObject) {
    const updateData = _.cloneDeep(outgoingSmsObject)
    // const smsBody = this.smsCut(updateData.body)
    const smsBody = updateData.body
    console.log('Send SMS - ', updateData)
    if (updateData.recipients && updateData.recipients.length > 0) {
      for (const recipientItem of updateData.recipients) {
        if (recipientItem.phoneNumber && recipientItem.phoneNumber !== '') {
          await axios
            .post(`outgoing_sms/send_sms`, { smsBody: smsBody, phoneNumber: recipientItem.phoneNumber })
            .then(async (response) => {
              if (response.status === 200) {
                recipientItem.sent = true
              }
            })
            .catch((error) => {
              throw error
            })
        }
      }
      let allSent = true
      for (const recipientItem of updateData.recipients) {
        if (recipientItem.sent === false) {
          allSent = false
          break
        }
      }
      updateData.sent = allSent
      updateData.updatedAt = new Date()

      // await view.$store
      //   .dispatch('outgoingSms/update', updateData)
      await axios
        .put(`outgoing_sms/${updateData.id}`, updateData)
        .then(async (response) => {})
        .catch((error) => {
          console.error(error)
          return
        })
    }
  },
}
